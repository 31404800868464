import React, { useEffect, useRef } from 'react';

import { IconCross } from '../../assets';
import { CallbackButton, Portal } from '../../components';
import { onEnterKeyPressDoCallback } from '@app/utils';

export const ModalSlider = (props) => {
  const { onClose, isOpen, children } = props;

  let modalWrapperRef = useRef(null);
  let closeIconRef = useRef(null);

  function closeOnEscKeyPressed(e) {
    if (e.keyCode === 27 && isOpen && !e.ctrlKey && !e.shiftKey && !e.altKey) {
      onClose(e);
      closeModal(e);
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', closeOnEscKeyPressed);
    return () => {
      document.removeEventListener('keydown', closeOnEscKeyPressed);
    };
  }, [isOpen]);

  const closeModal = (event) => {
    if (event) {
      const domNodeBar = modalWrapperRef.current;
      const domCloseIconNode = closeIconRef.current;
      if (
        event?.target === domCloseIconNode ||
        domCloseIconNode?.contains(event.target)
      )
        onClose();
      else if (
        event?.target == domNodeBar ||
        domNodeBar?.contains(event.target)
      )
        return;
    }
    return onClose();
  };

  return (
    <Portal isShow={isOpen}>
      <div
        className={` flex items-center justify-center w-screen  h-screen modal-slider-bg ${
          isOpen ? 'flex' : 'hidden'
        }`}
        onClick={closeModal}
        onKeyPress={closeOnEscKeyPressed}>
        <div
          className="flex flex-col items-center justify-center w-full slider-modal__wrapper"
          ref={modalWrapperRef}>
          <div
            tabIndex={0}
            ref={closeIconRef}
            className="flex justify-center items-center mb-1 pb-2 md:mb-2 md:pb-4 w-full cursor-pointer text-secondary hover:text-accent"
            onClick={(e) => {
              if (!e.isDefaultPrevented()) closeModal(e);
            }}
            onKeyPress={(e) => {
              onEnterKeyPressDoCallback(e, () => {
                closeModal(e);
              });
            }}>
            <div className="slider-cancel  ">
              <IconCross className="w-5 h-5 duration-300 " />
            </div>
          </div>
          {children}
        </div>
      </div>
    </Portal>
  );
};

export const GroupsIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.08325 3.33333C2.08325 2.8731 2.45635 2.5 2.91659 2.5H7.91659L9.99992 5H17.0833C17.5435 5 17.9166 5.37308 17.9166 5.83333V16.6667C17.9166 17.1269 17.5435 17.5 17.0833 17.5H2.91659C2.45635 17.5 2.08325 17.1269 2.08325 16.6667V3.33333Z"
        strokeWidth="2"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M17.9166 9.16602H2.08325"
        strokeWidth="2"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M2.08325 6.66602V11.666"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9167 6.66602V11.666"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { inject, observer } from 'mobx-react';
import React from 'react';

import { ButtonTransparent } from '../ui';
import { ModalMain } from './ModalMain';

export const ModalInfo = inject(({ store }) => {
  return {
    closeCurrentModal: store.ui.closeCurrentModal,
  };
})(
  observer((props) => {
    const {
      closeCurrentModal,
      onClose,
      modalId,
      modalText,
      className,
      children,
    } = props;

    return (
      <ModalMain modalId={modalId} modalClassName={className || ''}>
        <div className="flex flex-col  w-full">
          <span className="w-full mb-3 text-primary text-reg font-sans break-words font-medium">
            {modalText}
          </span>
          <div className={'flex w-full flex-col'}>{children}</div>
          <div className="modal-control-buttons flex justify-center mt-4">
            <ButtonTransparent
              text="Закрыть"
              className="text-secondary p-3"
              callback={onClose ?? closeCurrentModal}
            />
          </div>
        </div>
      </ModalMain>
    );
  }),
);

import { authService } from '@app/stores/mainStore.store';
import { TOKEN_SORT } from '@app/utils';
import { debounce, isNil } from 'lodash-es';
import { action, autorun, makeAutoObservable, toJS } from 'mobx';

export class FilterModule {
  filter = {
    page: 0,
    limit: 10,
    sortBy: [],
    search: '',
  };

  totalItems = 0;

  get filterForQuery() {
    let _filter = toJS(this.filter);
    if (this.filter.sortBy?.[0]?.id) {
      _filter.sortBy = `${this.filter.sortBy[0].id}${TOKEN_SORT}${
        this.filter.sortBy[0]?.desc === false ? 1 : -1
      }`;
    }

    return _filter;
  }
  onFilter = ({ pageSize, pageIndex, sortBy, search, ...rest }) => {
    this.filter = {
      ...this.filter,
      ...rest,
      page: !isNil(pageIndex) ? pageIndex : this.filter.page,
      limit: !isNil(pageSize) ? pageSize : this.filter.limit,
      sortBy: !isNil(sortBy) ? sortBy : this.filter.sortBy,
      search: !isNil(search) ? search : this.filter.search,
    };
  };
  setFilter = (f) => (this.filter = f);

  constructor(initialState, callbackOnChange) {
    if (initialState) {
      this.filter = initialState;
    }
    this.callbackOnChange = callbackOnChange;
    makeAutoObservable(this, {
      onFilter: action.bound,
      setFilter: action.bound,
    });

    autorun(() => {
      if (this.filter && authService.isAuth) {
        const filter = this.filter;
        const onUpdateFilter = () =>
          this.callbackOnChange({
            ...filter,
            sortBy: filter.sortBy?.[0] && {
              [`${filter.sortBy[0].id}`]:
                filter.sortBy[0]?.desc === false ? 1 : -1,
            },
          });
        const debounced = debounce(onUpdateFilter, 499);
        debounced();
      }
    });
  }
}

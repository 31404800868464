import { Drawer, InterceptWantedDetailed } from '@app/components';
import { inject, observer } from 'mobx-react';

export const InterceptWantedDetailedDrawer = inject(({ store }) => ({
  isShow: store.ui.showDrawer.interceptWanted,
  onClose: () => {
    store.ui.setShowDrawer('interceptWanted', false);
    store.eventsInterceptions.clearCurrent();
  },
}))(
  observer((props) => {
    const { isShow, onClose } = props;
    return (
      <Drawer isShow={isShow} type="details_wanted" onCloseDrawer={onClose}>
        <InterceptWantedDetailed />
      </Drawer>
    );
  }),
);

export const WantedIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.74992 17.4993C4.67038 17.4993 5.41659 16.7531 5.41659 15.8327C5.41659 14.9122 4.67038 14.166 3.74992 14.166C2.82944 14.166 2.08325 14.9122 2.08325 15.8327C2.08325 16.7531 2.82944 17.4993 3.74992 17.4993Z"
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.74992 5.83333C4.67038 5.83333 5.41659 5.08717 5.41659 4.16667C5.41659 3.24619 4.67038 2.5 3.74992 2.5C2.82944 2.5 2.08325 3.24619 2.08325 4.16667C2.08325 5.08717 2.82944 5.83333 3.74992 5.83333Z"
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.74992 11.6673C4.67038 11.6673 5.41659 10.9212 5.41659 10.0007C5.41659 9.08015 4.67038 8.33398 3.74992 8.33398C2.82944 8.33398 2.08325 9.08015 2.08325 10.0007C2.08325 10.9212 2.82944 11.6673 3.74992 11.6673Z"
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.75 10H17.9167"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 15.834H17.9167"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 4.16602H17.9167"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
    </svg>
  );
};

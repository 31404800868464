import React from 'react';

export const IconArrowDown = ({ className }) => {
  return (
    <svg
      className={`arrowDown ${className || ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.175 9L12 12.7116L15.825 9L17 10.1482L12 15L7 10.1482L8.175 9Z" />
    </svg>
  );
};

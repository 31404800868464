import { socketService } from '../../..//stores/_shared/socketService.module';

export const accessApi = {
  getList: ({ params }) => {
    return socketService.emit({
      method: 'acl.list',
      params,
    });
  },
  deleteById: (params) => {
    return socketService.bulk({
      method: 'acl.delete',
      params: params.map((id) => ({ acl: { id } })),
    });
  },
  create: ({ acl }) => {
    return socketService.emit({
      method: 'acl.create',
      params: { acl },
    });
  },
  getById: (params) => {
    return socketService.emit({
      method: 'acl.get',
      params,
    });
  },

  update: (acl) => {
    return socketService.emit({
      method: 'acl.update',
      params: { acl },
    });
  },

  /**
   * @param {Array<Number>} params - array of ids to delete
   * @returns Promise
   */
};

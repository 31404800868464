import { IconEye } from '@asset/icons';
import { CallbackButton } from '@component/HOC';
import { useEffect, useState } from 'react';
export const Input = ({
  type,
  callback,
  defaultValue,
  className,
  labelText,
  textError,
  inputRef,
  hasErrors,
  isOpenEye,
  setOpenEye,
  isPassword = null,
  autoComplete,
  ...props
}) => {
  const ref = inputRef;
  const [value, setValue] = useState('');

  if (!labelText) {
    throw Error('Нужен labelText для инпута :)');
  }
  const onChange = (e) => {
    if (!callback) {
      throw Error('Нужен колбэк для инпута :)');
    }
    // надо для проверки значения на null инпута, чтобы в css контролировать label
    inputRef?.current?.setAttribute('data-value', e.target.value);
    callback(e.target.value);
  };
  useEffect(() => {
    if (
      defaultValue ||
      defaultValue === 0 ||
      defaultValue === false ||
      defaultValue.trim() === ''
    ) {
      setValue(defaultValue);
      ref?.current?.setAttribute('data-value', defaultValue);
    }
  }, [defaultValue]);
  useEffect(() => {
    return () => {
      setValue('');
      if (ref?.current) {
        ref?.current?.setAttribute('data-value', '');
      }
    };
  }, []);

  const toggleOpenEye = () => {
    setOpenEye(!isOpenEye);
  };

  return (
    <div
      className={`relative input-text__wrap  ${className ? className : ''} ${
        hasErrors ? 'error' : ''
      }`}>
      <input
        {...props}
        type={type}
        defaultValue={value}
        ref={ref}
        onInput={onChange}
        data-value={value}
        autoComplete={autoComplete || ''}
        className={`w-full
              h-14
              border-b 
              border-b-line
              focus:border-b-accent
              text-reg 
              text-primary 
              dark:text-white
              dark:bg-transparent
              pt-5
              input-text `}
      />
      <span className="bar" />
      <label className="absolute top-0 pointer-events-none text-gray dark:text-primary duration-200 ease">
        {labelText}
      </label>
      {isPassword && (
        <CallbackButton
          type={'button'}
          className="eyeIcon w-6 h-full flex items-center absolute  cursor-pointer justify-center focus:outline-black"
          callback={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleOpenEye();
          }}>
          <IconEye isOpen={isOpenEye} />
        </CallbackButton>
      )}
      {hasErrors && <div className="text-error text-sm">{textError}</div>}
    </div>
  );
};

export const UsersIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_953_4063)">
        <path
          d="M5.83333 14.1667C6.98393 14.1667 7.91667 13.2339 7.91667 12.0833C7.91667 10.9327 6.98393 10 5.83333 10C4.68274 10 3.75 10.9327 3.75 12.0833C3.75 13.2339 4.68274 14.1667 5.83333 14.1667Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M14.1666 14.1667C15.3172 14.1667 16.2499 13.2339 16.2499 12.0833C16.2499 10.9327 15.3172 10 14.1666 10C13.016 10 12.0833 10.9327 12.0833 12.0833C12.0833 13.2339 13.016 14.1667 14.1666 14.1667Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M10.0001 5.83268C11.1507 5.83268 12.0834 4.89994 12.0834 3.74935C12.0834 2.59876 11.1507 1.66602 10.0001 1.66602C8.84949 1.66602 7.91675 2.59876 7.91675 3.74935C7.91675 4.89994 8.84949 5.83268 10.0001 5.83268Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M10.0001 18.3327C10.0001 16.0315 8.13458 14.166 5.83341 14.166C3.53223 14.166 1.66675 16.0315 1.66675 18.3327"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M18.3333 18.3327C18.3333 16.0315 16.4678 14.166 14.1667 14.166C11.8655 14.166 10 16.0315 10 18.3327"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M14.1666 10.0007C14.1666 7.69948 12.3011 5.83398 9.99992 5.83398C7.69875 5.83398 5.83325 7.69948 5.83325 10.0007"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_953_4063">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from 'react';

export const IconArrowLeft = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className || ''}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2969 19.7161C10.6903 20.1037 11.3234 20.099 11.7111 19.7056C12.0987 19.3122 12.094 18.679 11.7006 18.2914L6.33022 13H20.0014C20.5537 13 21.0014 12.5523 21.0014 12C21.0014 11.4477 20.5537 11 20.0014 11H6.33683L11.7006 5.71513C12.094 5.32751 12.0987 4.69436 11.7111 4.30096C11.3234 3.90755 10.6903 3.90286 10.2969 4.29048L3.37269 11.1129C2.87577 11.6025 2.87577 12.4041 3.37269 12.8937L10.2969 19.7161Z" />
    </svg>
  );
};

import { useEffect, useState } from 'react';

export const useClickOutsideWrapper = (buttonRef, wrapperRef) => {
  const [isVisibleWrapper, setisVisibleWrapper] = useState(false);
  const toggleWrapperVisible = () => {
    setisVisibleWrapper(!isVisibleWrapper);
  };
  const setWrapperHidden = () => {
    setisVisibleWrapper(false);
  };
  useEffect(() => {
    document.addEventListener('click', handleClick, false);
    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });
  const handleClick = (event) => {
    const domNodeIcon = buttonRef.current;
    const domNodeBar = wrapperRef.current;
    if (
      (domNodeBar && event.target == domNodeBar) ||
      domNodeBar?.contains(event.target)
    )
      return;
    if (
      (domNodeBar && domNodeIcon && event.target == domNodeIcon) ||
      domNodeIcon?.contains(event.target)
    ) {
      toggleWrapperVisible();
    } else if (
      domNodeBar &&
      (event.target !== domNodeBar || !domNodeBar.contains(event.target)) &&
      isVisibleWrapper
    ) {
      setWrapperHidden();
    }
  };
  return { isVisibleWrapper, toggleWrapperVisible, setWrapperHidden };
};

export class UserModel {
  data = {
    id: '',
    username: '',
    password: '',
    mail: '',
    name: '',
    groups: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    passwordUpdatedAt: new Date(),
    data: { phone: '' },
  };

  serialize = (account) => {
    this.data = {
      ...account,
      data: { ...(account.data ?? {}), phone: account.data?.phone ?? '' },
      id: account?.id ?? '',
      username: account?.username ?? '',
      password: account?.password ?? '',
      mail: account?.mail ?? '',
      name: account?.name ?? '',
      groups: account?.groups ?? [],
    };
    return this.data;
  };

  getUser = () => ({ ...this.data });
}

import React from 'react';

import { PreloaderIcon } from '../../_other/PreloaderIcon';
import { CallbackButton } from '../../HOC';

export const ButtonFilled = ({
  className,
  text,
  submit = false,
  callback,
  disabled,
  isLoading = false,
  ...props
}) => {
  const _callbackButton = () => {
    if (!submit && callback) {
      callback();
    }
  };
  return (
    <CallbackButton
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      {...props}
      callback={_callbackButton}
      className={`button button-filled  focus:outline-black dark:focus:outline-white ${
        className ? className : ''
      }`}>
      {text}
      {isLoading && <PreloaderIcon className="w-8 h-8 ml-2" />}
    </CallbackButton>
  );
};

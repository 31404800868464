import { groupsApi } from '@app/common/api/groups';
import { FilterModule } from '@app/stores/_shared/filterModule';
import { requestAPI } from '@app/utils';
import { escapeRegExp, find } from 'lodash-es';
import { makeAutoObservable } from 'mobx';

class groupsStore {
  filterModule = undefined;
  filterModuleAll = undefined;
  filterModuleCamers = undefined;

  list = [];
  allList = [];
  listCamera = [];
  current = {};
  lib = [];

  isLoading = false;

  get listTable() {
    return this.list.map((c) => {
      return {
        ...c,
        index: c.id,
        action: { id: c.id, label: c.name, can: c._can },
      };
    });
  }

  get allListTable() {
    return this.allList.map((c) => {
      return {
        ...c,
        index: c.id,
        action: { id: c.id, label: c.name, can: c._can },
      };
    });
  }

  get ListTableCamera() {
    return this.listCamera.map((c) => {
      return {
        ...c,
        index: c.id,
        action: { id: c.id, label: c.name, can: c._can },
      };
    });
  }

  setIsLoading = (loading) => (this.isLoading = loading);
  setLib = (lib) => {
    this.lib = [
      {
        label: 'Группы пользователей',
        value: '',
        disabled: true,
      },
      ...lib,
    ];
  };

  clear = () => {
    this.setList([]);
    this.clearCurrent();
    this.setAllList([]);
  };

  setList = (groupsList) => (this.list = groupsList);
  setAllList = (groupsList) => (this.allList = groupsList);
  setListCamera = (camera) => (this.listCamera = camera);

  setCurrent = (group) => (this.current = group);
  clearCurrent = () => this.setCurrent({});

  getListCamera = async ({ limit, page, sortBy }) => {
    const params = {
      can: ['update', 'delete'],
      limit: 9999999999,
      offset: (page ?? 0) * (limit || 999999999),
    };

    await requestAPI({
      func: async () => await groupsApi.getListCamera({ params }),
      onLoad: (response) => {
        const list = response.cameras?.map((camera, index) => {
          return {
            ...camera,
            _can: response.can[camera.id],
          };
        });

        this.setListCamera(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения камер',
        callback: () => this.getList({ limit, page, sortBy }),
      },
      onLoading: this.setIsLoading,
    });
  };

  getAllList = async ({ limit, page, sortBy }) => {
    const params = {
      can: ['update', 'delete'],
      limit: 9999999999,
      offset: (page ?? 0) * (limit || 999999999),
    };

    await requestAPI({
      func: async () => await groupsApi.getList({ params }),
      onLoad: (response) => {
        this.filterModule.totalItems = response.count ?? 0;

        if (response.groups) {
          const parent = response.groups
            ?.map?.((group) => {
              return { ...group, parent: group.parent ?? group.id };
            })
            .reduce((acc, item, index, array) => {
              const parentName = item.path
                ?.split(' > ')
                .map((p) => {
                  return find(array, { id: p })?.name ?? '';
                })
                .join(' > ');
              return { ...acc, [item.id]: parentName };
            }, {});

          const getParentsName = (group) => {
            return typeof parent === 'object' ? parent[group.id] : '';
          };

          const list = response.groups?.map((group) => {
            return {
              ...group,
              parentName: getParentsName(group),
              _can: response.can[group.id],
            };
          });
          const lib = response.groups?.map((group) => {
            return {
              value: group.id,
              label: group.name,
              type: 'usersGroup',
            };
          });
          this.setList(list);
          this.setLib(lib);
        } else {
          this.setList([]);
        }
      },
      onErrorMessage: {
        message: 'Ошибка получения групп пользователей ',
        callback: () => this.getList({ limit, page, sortBy }),
      },
      onLoading: this.setIsLoading,
    });
  };

  getList = async ({ limit, page, sortBy, search }) => {
    const params = {
      can: ['update', 'delete'],
      include: ['account'],
      limit: limit,
      offset: (page ?? 0) * (limit || 10),
    };
    if (sortBy) {
      params.sort = sortBy;
    }
    if (search) {
      params.filter = {
        $or: [
          ...(search.match(/^[0-9a-f]{24}$/) ? [{ id: search }] : []),
          { plate_number: { $regex: escapeRegExp(search), $options: 'i' } },
          { name: { $regex: escapeRegExp(search), $options: 'i' } },
          { comment: { $regex: escapeRegExp(search), $options: 'i' } },
        ],
      };
    }

    await requestAPI({
      func: async () => await groupsApi.getList({ params }),
      onLoad: (response) => {
        this.filterModule.totalItems = response.count ?? 0;

        if (response.groups) {
          const parent = response.groups
            ?.map?.((group) => {
              return { ...group, parent: group.parent ?? group.id };
            })
            .reduce((acc, item, index, array) => {
              const parentName = item.path
                ?.split(' > ')
                .map((p) => {
                  return find(array, { id: p })?.name ?? '';
                })
                .join(' > ');
              return { ...acc, [item.id]: parentName };
            }, {});

          const getParentsName = (group) => {
            return typeof parent === 'object' ? parent[group.id] : '';
          };

          const list = response.groups?.map((group) => {
            return {
              ...group,
              parentName: getParentsName(group),
              _can: response.can[group.id],
            };
          });
          this.setList(list);
        } else {
          this.setList([]);
        }
      },
      onErrorMessage: {
        message: 'Ошибка получения групп пользователей ',
        callback: () => this.getList({ limit, page, sortBy }),
      },
      onLoading: this.setIsLoading,
    });
  };

  addItem = async (group) => {
    const { hasError } = await requestAPI({
      func: async () => await groupsApi.create({ group }),
      onLoad: (response) => {
        this.getList({ limit: 10, page: 0 });
      },
      onErrorMessage: {
        message: 'Ошибка добавления групп ',
        callback: () => this.addItem(),
      },
      onLoadMessage: `Группа добавлена`,
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  deleteItem = async (id) => {
    await requestAPI({
      func: async () => await groupsApi.deleteById([id]),
      onLoad: (response) => {
        this.setList(this.list.filter((group) => group.id !== id));
      },
      onErrorMessage: {
        message: 'Ошибка удаления группы',
        callback: () => this.deleteItem(id),
      },
      onLoadMessage: (response) => `Группа удалена`,
      onLoading: this.setIsLoading,
    });
  };

  getCurrentById = async (id) => {
    const { hasError } = await requestAPI({
      func: async () => await groupsApi.getById({ group: { id } }),
      onLoad: (response) => {
        this.current = response.group;
        this.setCurrent(response.group);
      },
      onErrorMessage: {
        message: 'Ошибка получения одной группы',
        callback: () => this.getCurrentById(id),
      },
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  updateItem = async (group) => {
    const { hasError } = await requestAPI({
      func: async () => await groupsApi.update(group),
      onErrorMessage: {
        message: 'Ошибка обновления группы ',
        callback: () => this.updateItem(),
      },
      onLoadMessage: `Группа обновлена`,
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  constructor() {
    makeAutoObservable(this);

    this.filterModule = new FilterModule(
      {
        page: 0,
        limit: 10,
        sortBy: [],
        search: '',
      },
      this.getList,
    );
    this.filterModuleAll = new FilterModule(
      {
        page: 0,
        limit: 9999999999,
      },
      this.getAllList,
    );
    this.filterModuleCamers = new FilterModule(
      {
        page: 0,
        limit: 9999999999,
      },
      this.getListCamera,
    );
  }
}

export { groupsStore };

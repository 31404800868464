import { inject, observer } from 'mobx-react';
import React from 'react';

export const ButtonOpenModal = inject(({ store }) => ({
  setIsShowModal: store.ui.setCurrentModalId,
}))(
  observer(({ setIsShowModal, modalId, callback, Component, ...props }) => {
    const _callback = () => {
      setIsShowModal(modalId);
      callback && callback();
    };

    const ComponentWrapped = ({ callback, ...props }) => (
      <Component callback={_callback} {...props} />
    );
    return <ComponentWrapped callback={_callback} {...props} />;
  }),
);

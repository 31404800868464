import { socketService } from '../../..//stores/_shared/socketService.module';

export const cameraApi = {
  getList: ({ params }) => {
    return socketService.emit({
      // method: 'rtms.camera.list',
      method: 'camera.list',
      params,
    });
  },
};

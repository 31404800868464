import { inject, observer } from 'mobx-react';
import React from 'react';

import { PortalModal } from '../../_other';
import { ModalDefault } from '../ModalDefault';

const _Modal = ({
  onClose,
  onOpen,
  closeCurrentModal,
  children,
  openedModalId,
  modalId,
  modalClassName,
  iconCloseClassName,
}) => {
  const _onClose = () => {
    if (onClose && typeof onClose === 'function') onClose();

    closeCurrentModal();
  };
  const isShow = openedModalId === modalId;

  return (
    <PortalModal
      isShow={isShow}
      Element={({ state }) => (
        <ModalDefault
          onOpen={onOpen}
          onClose={_onClose}
          className={modalClassName}
          iconCloseClassName={iconCloseClassName}
          isOpen={isShow}>
          {children}
        </ModalDefault>
      )}
    />
  );
};

const mapMobxToProps = ({ store }) => {
  return {
    openedModalId: store.ui.openedModalId,
    closeCurrentModal: store.ui.closeCurrentModal,
  };
};

const _WithStore = inject(mapMobxToProps)(observer(_Modal));
export const ModalMain = _WithStore;

import { observer } from 'mobx-react';
import React from 'react';
import { IconWarning } from '../../assets';

export const ErrorSnackbar = observer((props) => {
  const { callback, onClose, message, className, callbackActionText, isShow } =
    props;

  return (
    <div
      className={`flex flex-0 justify-between items-center mb-2 transition-all   bg-primary rounded-[56px] gap-10 px-4 py-3 ${
        className || ''
      } ${isShow ? 'lightSpeedInRight' : 'lightSpeedOutRight'}`}
      style={{
        animationDuration: '0.5s',
      }}>
      <button
        type={'button'}
        className="flex items-center gap-2"
        onClick={onClose}>
        <IconWarning className="min-w-4 min-h-4 w-4 h-4" />
        <span className="snackbar-text select-none text-left">{message}</span>
      </button>
      {callback && (
        <button
          type={'button'}
          className="snackbar-button"
          onClick={() => {
            callback();
            onClose();
          }}>
          {callbackActionText}
        </button>
      )}
    </div>
  );
});

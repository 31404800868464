import React, { useEffect } from 'react';

export const InputSwitch = ({
  id,
  defaultValue,
  inputRef,
  callback,
  hasErrors,
  textError,
  className = '',
}) => {
  const ref = inputRef;
  const onChange = (e) => {
    e.stopPropagation();
    if (!callback) {
      throw Error('Нужен колбэк для инпута :)');
    }
    if (!id) {
      throw Error('Нужен ID для инпута :)');
    }
    return callback(!defaultValue);
  };
  useEffect(() => {
    if (defaultValue == undefined) {
      callback(false);
    }
  }, []);
  return (
    <div className={`flex flex-col ${className}`}>
      <div
        className={`relative inline-block w-10 mr-2 align-middle transition duration-200 ease-in select-none `}>
        <input
          onChange={onChange}
          ref={ref}
          checked={defaultValue}
          value={defaultValue}
          type="checkbox"
          name="toggle"
          id={id}
          className="toggle-checkbox focus:outline-black absolute block w-6 h-6 rounded-full bg-gray shadow-checkbox appearance-none cursor-pointer"
        />
        <label
          htmlFor={id}
          className="toggle-label block overflow-hidden h-4 rounded-full bg-border cursor-pointer w-full"
        />
      </div>
      {hasErrors && <div className="text-error text-sm">{textError}</div>}
    </div>
  );
};

import { socketService } from '../../..//stores/_shared/socketService.module';

export const eventsApi = {
  getList: ({ params }) => {
    return socketService.emit({
      method: 'interceptor.event.list',
      params,
    });
  },
  getById: (params) => {
    return socketService.emit({
      method: 'interceptor.event.get',
      params,
    });
  },

  subscribeEvents: ({ filter }) => {
    return socketService.emit({
      method: 'interceptor.event.subscribe',
      params: filter,
    });
  },
  unsubscribeEvents: (params = {}) => {
    return socketService.emit({
      method: 'interceptor.event.unsubscribe',
      params,
    });
  },
  onNewEvent: (params = {}) => {
    return socketService.emit({
      method: 'interceptor.event.unsubscribe',
      params,
    });
  },
};

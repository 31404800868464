export const ReportsIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_953_7241)">
        <path
          d="M6.81033 2.29883C5.79175 2.72118 4.87512 3.33973 4.10752 4.10732C2.59949 5.61535 1.66675 7.69868 1.66675 9.99989C1.66675 14.6023 5.39771 18.3332 10.0001 18.3332C12.3012 18.3332 14.3846 17.4005 15.8926 15.8924C16.6602 15.1248 17.2788 14.2082 17.7011 13.1896"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M10 9.99935H18.3333C18.3333 5.39697 14.6024 1.66602 10 1.66602V9.99935Z"
          strokeWidth="2"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_953_7241">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { inject, observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const RedirectIfIsAuth = inject(({ store }) => ({
  isAuth: store.auth.isAuth,
}))(
  observer((props) => {
    const { isAuth } = props;
    const history = useHistory();
    const location = useLocation();
    const pathFrom = useRef({});
    useEffect(() => {
      if (isAuth) {
        const to = pathFrom.current.path;

        const path = !to || to == '/' ? '/map' : location.pathname;

        history.push({
          pathname: path,
          search: pathFrom.current.search ?? undefined,
        });
      } else {
        pathFrom.current = {
          path: location.pathname,
          query: location.search ?? undefined,
        };
      }
    }, [isAuth]);
    return null;
  }),
);

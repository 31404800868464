import { inject, observer } from 'mobx-react';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { RouteLoading } from '../components';
import { PageContainer } from '../layouts/PageContainer';
import { NotFoundPage } from '../pages/NotFound';

const ListPage = lazy(() => import('../pages/Reports/routes/ListPage'));
const TableEvents = lazy(() => import('../pages/Reports/routes/TableEvents'));
const TableAccounts = lazy(() =>
  import('../pages/Reports/routes/TableAccounts'),
);

export const RoutesReports = inject(({ store }) => ({}))(
  observer(() => {
    let { path } = useRouteMatch();
    const homePath = path;

    return (
      <>
        <Suspense fallback={<RouteLoading />}>
          <PageContainer>
            <Switch>
              <Route path={homePath}>
                <Switch>
                  <Route exact path={homePath + '/events'}>
                    <TableEvents goBackUrl={homePath} />
                  </Route>
                  <Route exact path={homePath + '/accounts'}>
                    <TableAccounts goBackUrl={homePath} />
                  </Route>
                  <Route path={'/'}>
                    <ListPage goBackUrl={homePath} />
                  </Route>
                  <Route path={'*'}>
                    <NotFoundPage goBackUrl={homePath} />
                  </Route>
                </Switch>
              </Route>

              <Redirect from={`${path}`} to={homePath} />
              <Route path={'*'}>
                <NotFoundPage goBackUrl={homePath} />
              </Route>
            </Switch>
          </PageContainer>
        </Suspense>
      </>
    );
  }),
);

import { memo, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';

import { IconArrowDown, IconCross } from '../../../assets';

export const BaseSelectMultiple = memo(
  ({
    callback,
    hasError,
    options,
    disabled,
    value,
    className,
    hasSelectAll,
    isLoading,
    placeholder,
    Icon,
    overrideStings,
    label,
    allItemsAreSelectedLabel,
    ...props
  }) => {
    if (!options) {
      throw Error('Нужны options для селекта :)');
    }
    const onChange = (values) => {
      if (!callback) {
        throw Error('Нужен колбэк для селекта :)');
      }

      callback(values);
    };

    return (
      <div className={'flex flex-col flex-grow custom-multiple-select'}>
        {label && <span className="label">{label}</span>}
        <MultiSelect
          {...props}
          overrideStrings={{
            allItemsAreSelected: allItemsAreSelectedLabel ?? 'Все опции',
            clearSearch: 'Очистить',
            clearSelected: 'Очистить выбранные',
            noOptions: 'Ничего нет',
            search: 'Поиск',
            selectAll: 'Выбрать все',
            selectSomeItems: placeholder ?? 'Выберите',
            ...overrideStings,
          }}
          ArrowRenderer={(props) => (
            <IconArrowDown
              {...props}
              className={` w-6 h-6 pl-1 fill-current text-secondary`}
            />
          )}
          ClearSelectedIcon={
            <IconCross className={` w-5 h-5 p-1 fill-current text-secondary`} />
          }
          ClearIcon={
            <IconCross className={` w-5 h-5 p-1 fill-current text-secondary`} />
          }
          hasSelectAll={hasSelectAll}
          isLoading={isLoading}
          labelledBy={placeholder}
          value={value ?? []}
          options={options ?? []}
          debounceDuration={100}
          disabled={disabled}
          onChange={onChange}
          className={className}
        />
      </div>
    );
  },
);

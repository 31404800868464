import { socketService } from '../../..//stores/_shared/socketService.module';

export const wantedApi = {
  getLibReasons: async () => {
    const res = await socketService.emit({
      method: 'interceptor.event.reasons',
    });
    const toOptions = (o) =>
      Object.entries(o || {}).map(([value, label]) => ({ value, label }));
    return toOptions(res.reasons);
  },
  getLibVehicleClasses: async () => {
    const res = await socketService.emit({
      method: 'interceptor.event.vehicle_classes',
    });
    const toOptions = (o) =>
      Object.entries(o || {}).map(([value, label]) => ({ value, label }));
    return toOptions(res.vehicle_classes);
  },
  getLibTags: async () => {
    const res = await socketService.emit({
      method: 'interceptor.wanted.tags',
    });
    const toOptions = (o) =>
      Object.entries(o || {}).map(([value, label]) => ({ value, label }));
    return toOptions(res.tags);
  },
  getList: ({ params }) => {
    return socketService.emit({
      method: 'interceptor.wanted.list',
      params,
    });
  },
  getById: (id) => {
    return socketService.emit({
      method: 'interceptor.wanted.get',
      params: { interceptor_wanted: { id } },
    });
  },

  /**
   * @param {Array<Number>} params - array of ids to delete
   * @returns Promise
   */
  deleteByIds: (params) => {
    return socketService.bulk({
      method: 'interceptor.wanted.delete',
      params: params.map((id) => ({ interceptor_wanted: { id } })),
    });
  },
  update: (interceptor_wanted) => {
    return socketService.emit({
      method: 'interceptor.wanted.upsert',
      params: { interceptor_wanted },
    });
  },
  updateList: (interceptor_wanted) => {
    return socketService.bulk({
      method: 'interceptor.wanted.upsert',
      params: interceptor_wanted,
    });
  },
  create: ({ interceptor_wanted }) => {
    return socketService.emit({
      method: 'interceptor.wanted.create',
      params: { interceptor_wanted },
    });
  },
  deleteItemByTeg: (params) => {
    return socketService.bulk(
      {
        method: 'interceptor.wanted.list',
        params: params.map((tags) => ({ filter: { tags } })),
        return: false,
      },
      {
        method: 'interceptor.wanted.delete',
        params: {},
        loop: '0.interceptor_wanted',
        set: {
          'interceptor_wanted.id': '$.id',
        },
        return: false,
      },
    );
  },
};

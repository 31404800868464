import { inject, observer } from 'mobx-react';
import React from 'react';

import { PageLoading, PreloaderIcon } from '../../../components';

export const Preloader = inject(({ store }) => ({
  isLoading: store.wantedInterceptions.isLoading,
}))(
  observer((props) => {
    const { isLoading, children } = props;

    return (
      <div className="">
        {isLoading ? (
          <div className="w-8 h-8  flex  justify-center items-center">
            <PreloaderIcon className="w-8 h-8 text-accent" />
          </div>
        ) : (
          children
        )}
      </div>
    );
  }),
);

export const AccessPreloader = inject(({ store }) => ({
  isLoading: store.access.isLoading,
}))(observer(PageLoading));

import { socketService } from '@app/stores/_shared/socketService.module';
import { EAppPermissions } from '@app/utils';
import { get } from 'lodash-es';

export const authApi = {
  loginWithCredentials: ({ username, password }) => {
    return socketService.emit({
      method: 'account.login',
      params: {
        auth: { username, password },
      },
    });
  },
  loginWithToken: ({ token }) => {
    return socketService.emit({
      method: 'account.loginWithToken',
      params: {
        auth: { token },
      },
    });
  },
  accountCurrentGet: () => {
    return socketService.emit({
      method: 'account.get',
      params: {},
    });
  },
  accountServerInfo: () => {
    return socketService.emit({
      method: 'global.params',
    });
  },
  accountAclGet: async () => {
    const res = await socketService.bulk({
      method: 'acl.can',
      params: EAppPermissions.map((modelName) => ({
        query: {
          actions: [
            'admin',
            'create',
            'list',
            ...(modelName === 'CheckpointApplication' ? ['moderate'] : []),
            ...(modelName === 'Camera' ? ['rtms', 'intercept'] : []),
            ...(modelName === 'Storage' ? ['upload'] : []),
          ],
          model_name: modelName,
          what: [],
        },
      })),
    });

    let permissions = {};

    for (let item of res) {
      permissions[get(item, 'params.query.model_name')] = get(
        item,
        'data.results.$',
      );
    }
    return permissions;
  },
};

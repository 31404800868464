import { authService } from '@app/stores/mainStore.store';
import { utils } from 'xlsx';

import { store } from '../stores';
import { pushSnackbar } from '../stores/index';

export function removeEmpty(obj) {
  const finalObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const nestedObj = this.removeEmpty(obj[key]);
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj;
      }
    } else if (Array.isArray(obj[key])) {
      if (obj[key].length && obj[key] === 'object') {
        obj[key].forEach((x) => {
          const nestedObj = this.removeEmpty(x);
          if (Object.keys(nestedObj).length) {
            finalObj[key] = finalObj[key]
              ? [...finalObj[key], nestedObj]
              : [nestedObj];
          }
        });
      } else if (obj[key].length > 0) {
        finalObj[key] = obj[key];
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key];
    }
  });
  return finalObj;
}

export async function requestAPI({
  func,
  onLoad,
  onError,
  onLoadMessage,
  onErrorMessage,
  onLoading,
}) {
  let hasError = false;
  let response = null;
  onLoading && onLoading(true);
  try {
    response = await func();

    if (response) {
      if (onLoad) onLoad(response);
      if (typeof onLoadMessage === 'function') {
        if (onLoadMessage(response) !== null)
          await pushSnackbar.success(onLoadMessage(response));
      } else if (typeof onLoadMessage === 'string') {
        await pushSnackbar.success(onLoadMessage);
      }
    }
  } catch (e) {
    hasError = true;
    if (e.name === 're-auth') {
      let isLogged = await authService.checkIsLogged();

      if (isLogged) {
        if (response) {
          response = await func();
          if (onLoad) onLoad(response);
          if (typeof onLoadMessage === 'function') {
            if (onLoadMessage(response) !== null)
              await pushSnackbar.success(onLoadMessage(response));
          } else if (typeof onLoadMessage === 'string') {
            await pushSnackbar.success(onLoadMessage);
          }
          hasError = false;
        }
      } else {
        store.auth.logout();
      }
    }
    if (hasError) {
      onError && onError(e);
      onErrorMessage?.message &&
        (await pushSnackbar.error(
          `${onErrorMessage.message} ${e?.response?.data ?? e.message ?? e}`,
          onErrorMessage.callback,
        ));
    }
  } finally {
    onLoading && onLoading(false);
  }
  return { hasError, response };
}

export const onEnterKeyPressDoCallback = (e, callback) => {
  // eslint-disable-next-line
  if (e.keyCode === 13 || e.key == 'Enter') {
    return callback();
  }
};

export function downloadFile(text, name, type) {
  const a = document.createElement('a');
  let file = new Blob([text], { type: type });
  a.href = URL.createObjectURL(file);
  document.body.appendChild(a);
  a.download = name;
  a.click();
  a.remove();
}

export const trimStringByLength = (string, targetLength) => {
  if (string.length < targetLength) {
    return string;
  } else {
    // eslint-disable-next-line
    return string.substring(0, targetLength) + '...';
  }
};

export function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

export function detectIOSOrSafari() {
  // https://stackoverflow.com/q/9038625/3569421
  if (
    (navigator.userAgent.indexOf('Chrome') <= 0 &&
      navigator.userAgent.indexOf('Safari') >= 0) ||
    (navigator.userAgent.indexOf('Mac') >= 0 && 'ontouchend' in document)
  )
    return true;
  switch (navigator.platform) {
    case 'iPad Simulator':
    case 'iPhone Simulator':
    case 'iPod Simulator':
    case 'iPad':
    case 'iPhone':
    case 'iPod':
      return true;
  }
  return false;
}

function appendLeadingZeroes(n) {
  if (n <= 9) {
    return '0' + n;
  }
  return n;
}

export const formatDateToDefaultString = (current_datetime) => {
  if (!current_datetime) return current_datetime;
  return (
    current_datetime.getFullYear() +
    '-' +
    appendLeadingZeroes(current_datetime.getMonth() + 1) +
    '-' +
    appendLeadingZeroes(current_datetime.getDate())
  );
};

export const createError = (message, name) => ({
  message: message.toString().replace(/^(Error: ?)+/g, ''),
  name,
});

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const make_cols = (refstr) => {
  let o = [],
    C = utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i };
  return o;
};

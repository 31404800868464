import { socketService } from '../../..//stores/_shared/socketService.module';

export const usersApi = {
  getList: ({ params }) => {
    return socketService.emit({
      method: 'account.list',
      params,
    });
  },

  create: ({ account }) => {
    return socketService.emit({
      method: 'account.create',
      params: { account },
    });
  },

  deleteByIds: (params) => {
    return socketService.bulk({
      method: 'account.delete',
      params: params.map((id) => ({ account: { id } })),
    });
  },
  getById: (params) => {
    return socketService.emit({
      method: 'account.get',
      params,
    });
  },

  update: (account) => {
    return socketService.emit({
      method: 'account.update',
      params: { account },
    });
  },

  /**
   * @param {Array<Number>} params - array of ids to delete
   * @returns Promise
   */
};

import { inject, observer } from 'mobx-react';
import React from 'react';

export const StorageOccupiedWidget = inject(({ store }) => ({
  isAuth: store.auth.isAuth,
}))(
  observer((props) => {
    const { storage, isAuth } = props;
    let occupiedStoragePercent = 30;
    let radius = 25;
    let circumference = 2 * radius * Math.PI;
    let percentValue = (occupiedStoragePercent / 100) * circumference;
    if (!isAuth) return null;
    return (
      <>
        {!isNaN(+occupiedStoragePercent) && (
          <div
            className={
              'w-18 flex text-primary justify-center items-center whitespace-no-wrap cursor-default text-xs'
            }
            id={'storage-widget'}>
            <div id="storage-occupied-percent">
              <span>ROM :</span>{' '}
              <span className={'dark:text-white '}>
                {parseInt(occupiedStoragePercent)}%
              </span>
            </div>
            <div
              style={{ marginLeft: 5 }}
              id="chart-box"
              title={'Процент занятого пространства'}>
              <svg viewBox="0 0 100 100">
                <circle
                  r={radius}
                  cx="50"
                  cy="50"
                  id="pie"
                  style={{
                    strokeDasharray: percentValue + ' ' + circumference,
                    stroke: 'hsl(0 ,' + occupiedStoragePercent + '%, 50%)',
                  }}
                />
              </svg>
            </div>
          </div>
        )}
      </>
    );
  }),
);

import DateTimePicker from 'react-datetime-picker';

import { IconCalendar, IconCross } from '../../../assets';

export const InputDate = ({
  callback,
  hasErrors,
  textError,
  value,
  className,
  label,
}) => {
  const onChange = (v) => {
    callback && callback(v);
  };
  return (
    <div className={`relative flex flex-col  ${className ? className : ''}`}>
      {label && <span>{label}</span>}
      <DateTimePicker
        locale={'ru-RU'}
        className={
          'dark:bg-transparent dark:text-white group  outline-none h-full '
        }
        calendarIcon={
          <IconCalendar
            className={'min-w-6 w-6 text-secondary group-focus:text-accent'}
          />
        }
        clearIcon={
          <IconCross
            className={'min-w-6 w-6 text-secondary group-focus:text-accent'}
          />
        }
        disableClock={true}
        format={'dd/MM/yyyy HH:mm:ss'}
        onChange={onChange}
        openWidgetsOnFocus={true}
        value={value}
        maxDate={new Date('9999')}
      />

      {hasErrors && <div className="text-error text-sm">{textError}</div>}
    </div>
  );
};

import React from 'react';

export const IconUser = ({ className }) => {
  return (
    <svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00016 6.66732C9.47292 6.66732 10.6668 5.47341 10.6668 4.00065C10.6668 2.52789 9.47292 1.33398 8.00016 1.33398C6.5274 1.33398 5.3335 2.52789 5.3335 4.00065C5.3335 5.47341 6.5274 6.66732 8.00016 6.66732Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14 14.666C14 11.3523 11.3137 8.66602 8 8.66602C4.6863 8.66602 2 11.3523 2 14.666"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

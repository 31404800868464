import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonTransparent } from '../ui';
import { ModalMain } from './ModalMain';

export const ModalDelete = inject(({ store }) => {
  return {
    closeCurrentModal: store.ui.closeCurrentModal,
  };
})(
  observer((props) => {
    const { closeCurrentModal, onDelete, modalId, modalText, className } =
      props;
    const {t} = useTranslation('buttons') 

    return (
      <ModalMain modalId={modalId} modalClassName={className || ''}>
        <div className="flex flex-col">
          <span className="w-full mb-6 text-primary text-reg font-sans break-words font-medium">
            {modalText}
          </span>
          <div className="modal-control-buttons -mr-4">
            <ButtonTransparent
              text={`${t('cancel')}`}
              className="text-secondary px-8 py-3"
              callback={closeCurrentModal}
            />
            <ButtonTransparent
              text="Удалить"
              className="px-8 py-3"
              callback={async () => {
                await onDelete();
                closeCurrentModal();
              }}
            />
          </div>
        </div>
      </ModalMain>
    );
  }),
);

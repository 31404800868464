import { PortalModal } from '@app/components';
import { inject, observer } from 'mobx-react';
import { useRef } from 'react';

export const ImageModal = inject(({ store }) => ({
  setShowDrawer: store.ui.setShowDrawer,
  isShow: store.ui.showDrawer.imageModal,
  imageModalInfo: store.eventsInterceptions.imageModalInfo,
}))(
  observer((props) => {
    const { setShowDrawer, isShow, imageModalInfo } = props;
    let modalWrapperRef = useRef(null);
    let closeIconRef = useRef(null);

    const onClose = () => {
      setShowDrawer('imageModal', false);
    };

    const closeModal = (event) => {
      const domNodeBar = modalWrapperRef.current;
      const domCloseIconNode = closeIconRef.current;
      if (event.target == domNodeBar) {
        return onClose();
      }
      if (
        event.target === domCloseIconNode ||
        domCloseIconNode.contains(event.target)
      ) {
        onClose();
      }
      if (domNodeBar.contains(event.target)) {
        return null;
      }
    };

    return (
      <PortalModal
        isShow={isShow}
        Element={() => {
          return (
            <div
              ref={modalWrapperRef}
              tabIndex={0}
              role={'button'}
              onClick={closeModal}
              className="modal-window">
              <div className={`modal-box bg-white`}>
                <img
                  className={'max-h-[500px] m-1'}
                  src={imageModalInfo.url}
                  alt={imageModalInfo.type}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }),
);

import { storageService } from '@app/common';
import { useClickOutsideWrapper } from '@app/hooks';
import { KorgauLogo } from '@asset/icons/svgJsIcons/KorgauLogo';
import { AppVersion } from '@component/AppVersion';
import { SelectLanguage } from '@component/SelectLanguage';
import { inject, observer } from 'mobx-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import {
  CallbackButton,
  MenuHamburger,
  navBarLinksData,
  ThemeSwitcher,
} from '../../components';
import { UserMenu } from './components';
import { NavLink } from './NavLink';

export const Sidebar = inject(({ store }) => ({
  isAuth: store.auth.isAuth,
  logout: store.auth.logout,
}))(
  observer((props) => {
    const { t } = useTranslation('buttons');
    const { isAuth, logout } = props;
    const menuIconRef = useRef(null);
    const menuListRef = useRef(null);
    const { isVisibleWrapper, toggleWrapperVisible, setWrapperHidden } =
      useClickOutsideWrapper(menuIconRef, menuListRef);

    const history = useHistory();
    const _logout = () => {
      logout();
      history.push('/');
      storageService.removeItem('MS-API');
    };

    return (
      <div className="flex flex-col w-[302px] min-w-[302px] h-auto min-h-screen">
        <div className="flex items-center w-full  flex-col max-h-screen min-h-screen top-0 sticky">
          <div className="flex  items-center w-full h-[68px] border-b-border border-r-border border-b border-r px-4">
            <div className=" flex items-center  w-auto ">
              <Link to="/">
                <div className="flex items-center  justify-center max-w-32 ">
                  <KorgauLogo className="dark:text-white  text-primary" />
                </div>
              </Link>
            </div>

            <MenuHamburger
              isOpenMenu={isVisibleWrapper}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleWrapperVisible();
              }}
              menuRef={menuIconRef}
              className="md:hidden"
            />
          </div>

          <div
            className={`flex flex-col w-full flex-0  md:pb-0  dropdown   border-r-border   border-r `}>
            <div
              className={`flex px-4 py-4 h-[68px] w-full border-b-border border-b`}>
              <UserMenu />
              <AppVersion className={'ml-auto'} />
            </div>
            <div
              className="  w-full items-center flex flex-col pt-4  pb-4 pr-6 "
              ref={menuListRef}>
              {isAuth &&
                navBarLinksData.get().map((link) => {
                  if (!link.permission) return null;
                  return (
                    <NavLink
                      exact={link.exact}
                      key={link.path}
                      onClick={setWrapperHidden}
                      labelText={link.label}
                      path={link.path}
                    />
                  );
                })}
            </div>
            <div className="mt-auto">
              <div className={'w-full pl-8 pr-8'}>
                <SelectLanguage />
              </div>
              <div
                className={`flex px-4 py-4 h-[68px] w-full border-border border-b border-t`}>
                <ThemeSwitcher />
              </div>
              <div
                className={`flex px-4 py-4 h-[68px] w-full border-b-border border-b`}>
                {isAuth && (
                  <>
                    <CallbackButton
                      className=" w-full  select-none px-4 py-2 hover:bg-bg dark:hover:bg-bg-3 menu-list__item flex justify-start whitespace-no-wrap"
                      callback={() => {
                        _logout();
                      }}>
                      <span className={'leading-[100%]'}>{t('exit')}</span>
                    </CallbackButton>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }),
);

import { reportApi } from '@app/common/api/reports';
import { requestAPI } from '@app/utils';
import dayjs from 'dayjs';
import { makeAutoObservable, reaction } from 'mobx';
import { utils, writeFile } from 'xlsx';
class reportsInterceptionsStore {
  list = [];
  listAccounts = [];
  cameras = [];
  totalCount = {
    reasons: 0,
    accepted: 0,
    acknowledged: 0,
  };
  totalCountAccounts = {
    count: 0,
    accepted: 0,
    efficiency: 0,
  };
  isLoading = false;
  totalItems = 0;
  filterEvents = {
    dateFrom: '',
    dateTo: '',
    accepted_by: 'true',
    acknowledged_by: [],
    reason: [],
    sources: [],
  };
  filterAccounts = {
    dateFrom: '',
    dateTo: '',
    acknowledged_by: [],
    group: [],
  };

  onFilter = (filter) => {
    this.filterEvents = {
      ...this.filterEvents,
      dateFrom: filter.dateFrom || this.filterEvents.dateFrom,
      dateTo: filter.dateTo || this.filterEvents.dateTo,
      accepted_by: filter.accepted_by || this.filterEvents.accepted_by,
      reason: filter.reason || this.filterEvents.reason,
      sources: filter.sources || this.filterEvents.sources,
    };
  };

  onFilterAccounts = (filter) => {
    this.filterAccounts = {
      ...this.filterAccounts,
      dateFrom: filter.dateFrom || this.filterAccounts.dateFrom,
      dateTo: filter.dateTo || this.filterAccounts.dateTo,
      acknowledged_by:
        filter.acknowledged_by || this.filterAccounts.acknowledged_by,
      group: filter.group || this.filterAccounts.group,
    };
  };

  onClearFilter = () => {
    this.setFilter({
      dateFrom: '',
      dateTo: '',
      accepted_by: [],
      reason: [],
      sources: [],
    });
  };

  onClearFilterAccounts = () => {
    this.setFilterAccounts({
      dateFrom: '',
      dateTo: '',
      acknowledged_by: [],
      group: [],
    });
  };

  totalCountCounter = (reason, results, field) => {
    return reason.map((r) => ({
      value: results.reduce(
        (a, b) =>
          a + (typeof b[field] === 'object' ? b[field][r.value] : b[field]),
        0,
      ),
      key: r.value,
    }));
  };

  setFilter = (f) => (this.filterEvents = f);
  setFilterAccounts = (f) => (this.filterAccounts = f);
  setIsLoading = (loading) => (this.isLoading = loading);

  get listTable() {
    return this.list;
  }
  get listTableAccounts() {
    return this.listAccounts;
  }

  getCurrentById = async ({ id, datetime }) => {
    const { hasError } = await requestAPI({
      func: async () =>
        await eventsApi.getById({
          event: { datetime, id },
          include: ['files', 'camera', 'server', 'wanted'],
        }),
      onLoad: async (v) => {
        this.current = {
          ...v,
          files: await Promise.all(
            (v.files || [])
              .map(async (f) => ({
                ...f,
                url: f.url,
              }))
              .sort((v, b) => v.type - b.type),
          ),
          _wanted: v.wanted || {},
          _server: v.server || {},
          _camera: v.camera || {},
          _detailShown: false,
        };
      },
      onErrorMessage: {
        message: 'Ошибка получения ',
        callback: () => this.getCurrentById(id),
      },
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  setList = (report) => {
    this.list = report;
  };
  setListAccounts = (report) => {
    this.listAccounts = report;
  };

  setCameras = (cameras) => {
    this.cameras = cameras;
  };

  getList = async ({ dateFrom, dateTo, reason, accepted_by, sources }) => {
    this.totalCount = {
      reasons: 0,
      accepted: 0,
      acknowledged: 0,
    };
    const params = {
      include: ['cameras', 'acknowledged'],
      reasons: [
        'fake-number',
        'unknown-driver',
        'debtor',
        'insurance',
        'technical-inspection',
        'wanted',
        'suspension',
        'criminal',
      ],
      group: {
        date: false,
        by: 'camera',
      },
    };
    params.filter = {};
    if (accepted_by !== 'all') {
      if (accepted_by === 'true') {
        params.accepted_by = true;
        params.filter.accepted_by = { $ne: '' };
      } else {
        params.filter.accepted_by = '';
      }
    }

    params.filter.datetime = {
      $lte: dateTo
        ? dayjs(dateTo).endOf('day').format()
        : dayjs().endOf('day').format(),
      $gte: dateFrom
        ? dayjs(dateFrom).startOf('minute').format()
        : dayjs().subtract(3, 'days').startOf('day').format(),
    };

    if (reason?.length) {
      params.reasons = reason.map((option) => {
        return option.value;
      });
      params.filter.reason = {
        $in: reason.map((option) => {
          return option.value;
        }),
      };
    }

    if (sources?.length) {
      params.filter.camera = {
        $in: sources.map((option) => {
          return option.value;
        }),
      };
    }

    await requestAPI({
      func: async () => await reportApi.getList({ params }),
      onLoad: async (results) => {
        const cameras = Object.keys(results.stats);
        this.setCameras(cameras);
        const list = Object.keys(results.stats).map((item) => {
          return {
            camera: results.cameras.map((cam) => {
              if (cam.id === item) return cam.name;
            }),
            reasons: reason.map((r) => ({
              value: results.stats[item].reduce(
                (a, b) => a + b.reasons[r.value],
                0,
              ),
              key: r.value,
              label: r.label,
            })),
            accepted: this.totalCountCounter(
              reason,
              results.stats[item],
              'accepted',
            ),
            acknowledged: this.totalCountCounter(
              reason,
              results.stats[item],
              'acknowledged',
            ),
          };
        });

        list.forEach((cam) => {
          this.totalCount = {
            reasons:
              this.totalCount.reasons +
              cam.reasons.reduce((a, b) => a + b.value, 0),
            accepted:
              this.totalCount.accepted +
              cam.accepted.reduce((a, b) => a + b.value, 0),
            acknowledged:
              this.totalCount.acknowledged +
              cam.acknowledged.reduce((a, b) => a + b.value, 0),
          };
        });
        this.setList(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения ориентировок ',
        callback: () =>
          this.getList({
            dateFrom,
            dateTo,
            reason,
            accepted_by,
            sources,
          }),
      },
      onLoading: this.setIsLoading,
    });
  };
  getListAccounts = async ({ dateFrom, dateTo, acknowledged_by }) => {
    const params = {
      accepted_by: acknowledged_by,
      group: {
        date: false,
        by: 'acknowledged_by',
      },
    };
    params.filter = {};
    params.filter.datetime = {
      $lte: dateTo
        ? dayjs(dateTo).endOf('day').format()
        : dayjs().endOf('day').format(),
      $gte: dateFrom
        ? dayjs(dateFrom).startOf('minute').format()
        : dayjs().subtract(3, 'days').startOf('day').format(),
    };

    await requestAPI({
      func: async () => await reportApi.getList({ params }),
      onLoad: async (results) => {
        const list = Object.keys(results.stats).map((acc) => {
          return {
            acknowledged_by: acc,
            count: results.stats[acc].reduce((a, b) => a + b.count, 0),
            accepted: results.stats[acc].reduce((a, b) => a + b.accepted, 0),
          };
        });
        this.totalCountAccounts = {
          count: list.reduce((a, b) => a + b.count, 0),
          accepted: list.reduce((a, b) => a + b.accepted, 0),
        };
        this.setListAccounts(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения ориентировок ',
        callback: () =>
          this.getListAccounts({
            dateFrom,
            dateTo,
            acknowledged_by,
          }),
      },
      onLoading: this.setIsLoading,
    });
  };

  doExport = async (tableId, tableName) => {
    var table_elt = document.getElementById(tableId);

    // Extract Data (create a workbook object from the table)
    var workbook = utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets['Sheet1'];
    utils.sheet_add_aoa(ws, [], { origin: -1 });

    // Specify file name
    const filename = `${tableName} (${dayjs(this.filterEvents.dateFrom).format(
      'DD.MM.YYYY',
    )} - ${dayjs(this.filterEvents.dateTo).format('DD.MM.YYYY')}).xlsx`;
    const filenameAcc = `${tableName} (${dayjs(
      this.filterAccounts.dateFrom,
    ).format('DD.MM.YYYY')} - ${dayjs(this.filterAccounts.dateTo).format(
      'DD.MM.YYYY',
    )}).xlsx`;
    writeFile(workbook, tableId === 'tableEventsXls' ? filename : filenameAcc);
  };

  doExportPdf = (tableID) => {
    const divContents = document.getElementById(tableID);
    const width = 800;
    const height = 600;
    const top = window.innerHeight / 2 - height / 2;
    const left = window.innerWidth / 2 - width / 2;
    const params = `modal=yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;

    const win = window.open('', '', params);
    win.location = 0;
    win.document.write(
      '<html><style>\n' +
        ' table {width: 100%;font: 14px;}\n' +
        ' table, th, td {\n' +
        '   border: 1px solid black;\n' +
        '   border-collapse: collapse;\n' +
        '   padding: 2px 3px;\n' +
        '   text-align: left;\n' +
        ' }\n' +
        '</style><body style="margin: 0">',
    );
    win.document.write(
      `<head><title>Отчет по ${
        tableID === 'tableEvents' ? ' событиям' : 'сотрудникам'
      }</title></head>`,
    );
    win.document.write(divContents.outerHTML);
    win.document.write('</body></html>');
    win.document.close();
    setTimeout(() => {
      win.focus();
      win.print();
      win.close();
    });
  };

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.filterEvents,
      () => {
        this.getList(this.filterEvents);
      },
    );

    reaction(
      () => this.filterAccounts,
      () => {
        this.getListAccounts(this.filterAccounts);
      },
    );
  }
}

export { reportsInterceptionsStore };

import { eventsApi } from '@app/common/api/events';
import { socketService } from '@app/stores/_shared/socketService.module';
import { getStoreListeners } from '@app/stores/mainStore.store';
import { get } from 'lodash-es';

import { storageService } from '../common';
import { ENotificationVariants } from '../components';
import { store } from '../stores';

export const TOKEN_KEY = '33232097a65';
export const TOKEN_SIGN_KEY = '3c32324097a66';

export const tokenService = {
  getToken: () => storageService.getItem(TOKEN_KEY),
  getTokenSign: () => storageService.getItem(TOKEN_SIGN_KEY),
  setToken: (query) => storageService.setItem(TOKEN_KEY, query),
  setTokenSign: (query) => storageService.setItem(TOKEN_SIGN_KEY, query),
  destroyToken: (query) => storageService.removeItem(TOKEN_KEY),
  destroyTokenSign: (query) => storageService.removeItem(TOKEN_SIGN_KEY),
};

export let pushSnackbar = {
  success: async (message, callback = null, callbackActionText = null) => {
    await store.ui.pushSnackbar(
      `${message || ''}`,
      { variant: ENotificationVariants.SUCCESS, callback, callbackActionText },
      `ok-${message}`,
    );
  },
  info: async (message, callback = null, callbackActionText = null) => {
    await store.ui.pushSnackbar(
      `${message || ''}`,
      { variant: ENotificationVariants.INFO, callback, callbackActionText },
      `ok-${message}`,
    );
  },
  error: async (
    message,
    callback,
    callbackActionText = 'Попробовать снова',
  ) => {
    await store.ui.pushSnackbar(
      `Ошибка: ${message}`,
      { variant: ENotificationVariants.ERROR, callback, callbackActionText },
      `error-${message}`,
    );
  },
};

export const hasPermission = (permission) => {
  if (typeof permission === 'string') {
    return get(store.auth.permissions, permission);
  }
  if (
    Array.isArray(permission) &&
    permission.every((p) => typeof p === 'string')
  ) {
    return [...permission].every((v) => {
      return get(store.auth.permissions, v);
    });
  }
  return false;
};

export const loadEventListeners = async () => {
  const isActiveMethod = storageService.getItem('activeMethod');
  let isLogged = await store.auth.checkIsLogged();
  if (isActiveMethod === 'true' && isLogged) {
    await eventsApi.subscribeEvents({});
  }
  await socketService.removeAllListeners('api-event');

  getStoreListeners().forEach((callback) => {
    socketService.on('api-event', callback);
  });
};


export const models = {
  Acl: 'Права доступа',
  Server: 'Сервер',
  Camera: 'Камера',
  Screen: 'Экран',
  Plan: 'План',
  Trigger: 'Триггер',
  Logger: 'Лог',
  FaceApi: 'Face API',
  Guard: 'Охрана',
  Storage: 'Хранилище',
  InterceptorWanted: 'Ориентировка',
  CheckpointSource: 'Источник на блок-посте',
  CheckpointRoute: 'Маршруты через блок-посты',
  CheckpointApplication: 'Заявка на передвижение',
  CheckpointRulePerson: 'Правило для людей на блок-посте',
  CheckpointRuleVehicle: 'Правило для транспорта на блок-посте',
};

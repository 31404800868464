import React from 'react';

export function PreloaderIcon({ className }) {
  return (
    <div className={`lds-ring ${className ?? ''}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

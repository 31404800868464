export const EventIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33333 17.4993H11.6667C11.6667 18.416 10.9167 19.166 10 19.166C9.08333 19.166 8.33333 18.416 8.33333 17.4993ZM17.5 15.8327V16.666H2.5V15.8327L4.16667 14.166V9.16602C4.16667 6.58268 5.83333 4.33268 8.33333 3.58268V3.33268C8.33333 2.41602 9.08333 1.66602 10 1.66602C10.9167 1.66602 11.6667 2.41602 11.6667 3.33268V3.58268C14.1667 4.33268 15.8333 6.58268 15.8333 9.16602V14.166L17.5 15.8327ZM14.1667 9.16602C14.1667 6.83268 12.3333 4.99935 10 4.99935C7.66667 4.99935 5.83333 6.83268 5.83333 9.16602V14.9993H14.1667V9.16602Z" />
    </svg>
  );
};

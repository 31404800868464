import React from 'react';
export const IconCrossRounded = ({ className }) => (
  <svg
    className={` fill-current ${className}`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3335 15.8263L15.8268 17.333L12.0002 13.5063L8.1735 17.333L6.66683 15.8263L10.4935 11.9997L6.66683 8.17301L8.1735 6.66634L12.0002 10.493L15.8268 6.66634L17.3335 8.17301L13.5068 11.9997L17.3335 15.8263ZM12.0002 1.33301C6.10683 1.33301 1.3335 6.10634 1.3335 11.9997C1.3335 17.893 6.10683 22.6663 12.0002 22.6663C17.8935 22.6663 22.6668 17.893 22.6668 11.9997C22.6668 6.10634 17.8935 1.33301 12.0002 1.33301Z"
      fill="#757575"
    />
  </svg>
);

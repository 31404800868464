import React, { useRef } from 'react';

export const InputRadioButton = ({
  id,
  callback,
  groupName,
  checked,
  radioValue,
  defaultValue,
  labelText,
  className,
  disabled,
}) => {
  const radioRef = useRef(null);

  if (!callback) {
    throw Error('need a radio callback )');
  }

  const onChangeRadio = () => {
    callback(radioValue);
  };

  return (
    <div
      className={`relative  flex items-center duration-200 ease-in radio-input__wrapper ${
        className ? className : ''
      }`}>
      <input
        hidden
        checked={radioValue === defaultValue}
        onChange={onChangeRadio}
        ref={radioRef}
        type="radio"
        name={groupName}
        value={radioValue}
        id={id}
        disabled={disabled}
        className="radio-input  cursor-pointer z-0 w-1 h-1  invisible"
      />
      <label
        htmlFor={`${id}`}
        className="text-reg leading-6 text-primary cursor-pointer user-select-none">
        <span className="radio-checkmark" />
        <span className="ml-4 select-none"> {labelText} </span>
      </label>
    </div>
  );
};

import { InterceptCamerasDetailed, Search } from '@app/components';
import { Button } from '@component/Map/components';
import { ReactComponent as ArrowLeftIcon } from '@component/Map/icons/arrow-left.svg';
import { ReactComponent as RingIcon } from '@component/Map/icons/ring.svg';

export const SideInfo = (props) => {
  const { isShow, onToggle } = props;
  return (
    <div
      // onCloseDrawer={onClose}
      className={`flex relative flex-col transition-all duration-300 ${
        isShow ? 'w-[320px] min-w-[320px] h-auto min-h-screen' : 'w-0 '
      }`}>
      <Button
        className={` ${isShow ? '-left-12' : '-left-20'} top-4 z-top
        bg-white dark:bg-bg-3 absolute
        flex items-center
        p-2 gap-2  rounded-[100px]
        cursor-pointer
        transition-all 
        duration-200
        `}
        onClick={onToggle}>
        <ArrowLeftIcon
          className={`min-w-6 min-h-6 h-6 w-6 stroke-current fill-[none] text-dark dark:text-white ${
            isShow && 'rotate-180'
          }`}
        />
        {!isShow && (
          <RingIcon
            className={' fill-current text-dark dark:text-white  min-w-4 '}
          />
        )}
      </Button>
      {isShow && (
        <>
          <div className="flex items-center w-full flex-col max-h-screen min-h-screen top-0 sticky">
            <div className="w-full h-14 p-4 pb-0">
              <Search />
            </div>
            <div
              className="flex flex-col w-full"
              style={{ height: 'calc(100vh - 56px' }}>
              <InterceptCamerasDetailed />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

class _localStorage {
  static getItem = (key) => localStorage.getItem(key);
  static removeItem = (key) => localStorage.removeItem(key);
  static setItem = (key, value) => {
    console.log(key, value, 'SET_ITEM');
    return localStorage.setItem(key, value);
  };
  static clear = () => localStorage.clear();
}
export const storageService = _localStorage;

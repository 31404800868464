import { storageService } from '@app/common';
import { InputSwitch } from '@component/ui';
import { t } from 'i18next';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles/Switch.module.css';

export const SwitchMapEventsFlow = inject(({ store }) => ({
  enabled: store.eventsInterceptions.isMapFlowEnabled,
  setIsEnabled: store.eventsInterceptions.setIsMapFlowEnabled,
}))(
  observer(({ enabled, setIsEnabled }) => {
    const [activeMethod, setActiveMethod] = useState(false);
    const onChange = (v) => {
      storageService.setItem('activeMethod', v);
      setIsEnabled(v);
      setActiveMethod(v);
    };
    const { t } = useTranslation('buttons');
    useEffect(() => {
      if (storageService.getItem('activeMethod') === 'true') {
        setIsEnabled(true);
        setActiveMethod(true);
      }
      return () => {
        onChange(false);
      };
    }, []);
    return (
      <div
        className={`${styles.container} transition-all duration-300 bg-white dark:bg-bg-3`}>
        <h4 className={'whitespace-nowrap text-primary dark:text-white'}>
          {t('streamOfEvents')}
        </h4>
        <InputSwitch
          className={'ml-4'}
          id={'map-flow-enabled'}
          callback={onChange}
          defaultValue={activeMethod}
        />
      </div>
    );
  }),
);

export const SwitchMapSourcesShow = inject(({ store }) => ({
  enabled: store.camerasInterceptions.isMapSourcesEnabled,
  setIsEnabled: store.camerasInterceptions.setIsMapSourcesEnabled,
}))(
  observer(({ enabled, setIsEnabled }) => {
    const onChange = (v) => setIsEnabled(v);
    const { t } = useTranslation('buttons');
    useEffect(() => {
      return () => {
        onChange(false);
      };
    }, []);
    return (
      <div
        className={`${styles.container} transition-all duration-300 bg-white dark:bg-bg-3`}>
        <h4 className={'whitespace-nowrap text-primary dark:text-white'}>
          {t('displayCameras')}
        </h4>
        <InputSwitch
          className={'ml-4'}
          id={'map-source-enabled'}
          callback={onChange}
          defaultValue={enabled}
        />
      </div>
    );
  }),
);

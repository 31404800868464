import { InputSwitch } from '@component/ui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ThemeSwitcher = inject(({ store }) => ({
  isDarkTheme: store.ui.isDarkTheme,
  setIsDarkTheme: store.ui.setIsDarkTheme,
}))(
  observer((props) => {
    const { isDarkTheme, setIsDarkTheme } = props;
    const { t } = useTranslation('buttons');
    return (
      <div className={` px-4 py-2  flex items-center w-full`}>
        <label
          htmlFor={'dark-theme'}
          className={'whitespace-nowrap pr-4 cursor-pointer'}>
          {t('theme')}
        </label>
        <InputSwitch
          id={'dark-theme'}
          defaultValue={isDarkTheme}
          className="ml-auto"
          callback={() => {
            setIsDarkTheme(!isDarkTheme);
          }}
        />
      </div>
    );
  }),
);

import { ReasonsWantedSelect } from '@component/LibSelects/ReasonsWantedSelect';
import { Filter } from '@component/Map/components/Filter';
import { InputDate, InputSearch } from '@component/ui';
import { debounce } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useCallback, useRef, useState } from 'react';

import { ReactComponent as Close } from '../icons/close-small.svg';
import { ReactComponent as FilterButton } from '../icons/filter.svg';
import { ReactComponent as IconSearch } from '../icons/search.svg';
import { useTranslation } from 'react-i18next';

export const Search = inject(({ store }) => ({
  clearFilter: store.eventsInterceptions.clearMapEventsFilter,
  filter: store.eventsInterceptions.filterMapEvents,
  filterChange: store.eventsInterceptions.filterChange,
}))(
  observer(({ clearFilter, filter, filterChange }) => {
    const { t } = useTranslation(['inputs', 'buttons']);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const toggleFilter = useCallback(
      () => setIsOpenFilter((prevState) => !prevState),
      [],
    );
    const ref = useRef(null);

    const onSearch = useCallback((e) => {
      filterChange({ search: e.target.value });
    }, []);
    const onChangeType = useCallback((reason) => {
      filterChange({ reason });
    }, []);
    const onChangeDate = useCallback((key, value) => {
      if (value && key === 'dateTo') {
        value.setHours(23, 59, 59, 999);
      }
      filterChange({
        [key]: value,
      });
    }, []);

    const onClearSearch = () => {
      ref.current.value = '';
      filterChange({ search: '' });
    };

    const onClearFilter = () => {
      clearFilter();
    };

    const debounced = debounce(onSearch, 500);

    return (
      <div className="relative h-full">
        <IconSearch className="fill-[none] absolute left-3 w-6" />
        <FilterButton
          className={`fill-[none] stroke-current absolute right-3 w-6 cursor-pointer
            hover:text-accent duration-200 transition-colors
            ${isOpenFilter ? 'text-accent' : 'text-secondary dark:text-white'}
          `}
          onClick={toggleFilter}
        />
        {filter.search !== '' && (
          <button
            className="absolute right-10 w-6 top-2"
            type={'button'}
            onClick={onClearSearch}>
            <Close className="" />
          </button>
        )}
        <input
          placeholder={t('numberGRNZ')}
          onInput={debounced}
          defaultValue={filter.search}
          ref={ref}
          className={`
                    w-full
                    dark:bg-bg-3
                    border
                    border-border
                    dark:border-secondary
                    transition
                    duration-200
                    hover:border-gray
                    dark:hover:border-bg-3
                    focus:border-secondary
                    rounded-[56px] text-reg
                    placeholder-current text-primary
                    dark:text-white
                    px-12
                    pr-12
                    h-full
                    input-text`}
        />
        <Filter isOpen={isOpenFilter} className="events">
          <h4 className={'text-h3 font-semi text-primary uppercase'}>
            {t('filtered', { ns: 'inputs' })}
          </h4>
          <div className={'flex flex-col'}>
            <div className={'flex items-center'}>
              <ReasonsWantedSelect
                className={' underlined max-w-[248px]'}
                type={'text'}
                labelText={t('reason', { ns: 'inputs' }) + ' *'}
                value={filter.reason}
                callback={onChangeType}
              />
            </div>
            <div className={'flex items-center mt-5'}>
              <InputDate
                label={t('from')}
                className={'h-full'}
                value={filter.dateFrom}
                callback={(v) => onChangeDate('dateFrom', v)}
              />
            </div>
            <div className={'flex items-center mt-4'}>
              <InputDate
                label={t('to')}
                className={'h-full'}
                value={filter.dateTo}
                callback={(v) => onChangeDate('dateTo', v)}
              />
            </div>
          </div>
          <button
            className={
              'block h-14 bg-accent rounded-full text-white text-center align-middle mt-2'
            }
            onClick={toggleFilter}>
            {t('apply', { ns: 'buttons' })}
          </button>
          <button
            className={
              'block h-14 border rounded-full text-center align-middle mt-2'
            }
            onClick={onClearFilter}>
            {t('reset', { ns: 'buttons' })}
          </button>
        </Filter>
      </div>
    );
  }),
);

export const LogsIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 1.66602H4.16659C3.70635 1.66602 3.33325 2.03911 3.33325 2.49935V17.4993C3.33325 17.9596 3.70635 18.3327 4.16659 18.3327H15.8333C16.2935 18.3327 16.6666 17.9596 16.6666 17.4993V2.49935C16.6666 2.03911 16.2935 1.66602 15.8333 1.66602Z"
        strokeWidth="2"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 5.83398H13.75"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 10H13.75"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 14.166H13.75"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25008 6.66667C6.71033 6.66667 7.08342 6.29358 7.08342 5.83333C7.08342 5.37308 6.71033 5 6.25008 5C5.78983 5 5.41675 5.37308 5.41675 5.83333C5.41675 6.29358 5.78983 6.66667 6.25008 6.66667Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25008 10.8327C6.71033 10.8327 7.08342 10.4596 7.08342 9.99935C7.08342 9.5391 6.71033 9.16602 6.25008 9.16602C5.78983 9.16602 5.41675 9.5391 5.41675 9.99935C5.41675 10.4596 5.78983 10.8327 6.25008 10.8327Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25008 15.0007C6.71033 15.0007 7.08342 14.6276 7.08342 14.1673C7.08342 13.7071 6.71033 13.334 6.25008 13.334C5.78983 13.334 5.41675 13.7071 5.41675 14.1673C5.41675 14.6276 5.78983 15.0007 6.25008 15.0007Z"
        fill="#333333"
      />
    </svg>
  );
};

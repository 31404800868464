export const EUserRoles = Object.freeze({
  USER: 'USER',
  ADVANCED: 'ADVANCED_USER',
  ADMIN: 'ADMIN',
});

export const ESortBy = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export let EAppPermissions = [
  'Account',
  'Acl',
  'Camera',
  'Screen',
  'Group',
  'Trigger',
  'Logger',
  'Server',
  'Taxonomy',
  'CheckpointApplication',
  'CheckpointSource',
  'CheckpointRoute',
  'CheckpointRulePerson',
  'CheckpointRuleVehicle',
  'Storage',
  'System',
  'LvsPacket',
  'InterceptorWanted',
];

export const ImportReasonsList = [
  {
    label: 'ГРНЗ',
    value: 'plate_number',
  },
  {
    label: 'Классификатор контроля',
    value: 'reason',
    disabled: false,
  },
  {
    label: 'Класс ТС',
    value: 'vehicle_class',
    disabled: false,
  },
  {
    label: 'Марка ТС',
    value: 'vehicle_mark',
  },
  {
    label: 'Модель ТС',
    value: 'vehicle_model',
  },
  {
    label: 'Год выпуска ТС',
    value: 'vehicle_year',
  },
  {
    label: 'Цвет ТС',
    value: 'vehicle_color',
  },
  {
    label: 'Имя владельца',
    value: 'name',
  },
  {
    label: 'Комментарий',
    value: 'comment',
  },
];

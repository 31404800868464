import { IconCheck } from '@asset/icons';
import { IconDown } from '@asset/icons/svgJsIcons/IconDown';
import { IconGroup } from '@asset/icons/svgJsIcons/IconGroup';
import { IconRight } from '@asset/icons/svgJsIcons/IconRight';
import { IconUser } from '@asset/icons/svgJsIcons/IconUser';
import { InputSearch } from '@component/ui';
import { useSyncFilterWithRoute } from '@hooks/useSyncFilterWithRoute';
import { uniqBy } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { useTranslation } from 'react-i18next';

const MultipleCheckbox = inject(({ store }) => ({
  itemUsers: store.users.allListTable,
  itemGroups: store.groupsInterceptions.allListTable,
  onFilter: store.access.filterModule.onFilter,
  eventsFilter: store.access.filterModule.filter,
  onLoadFilter: store.access.filterModule.setFilter,
}))(
  observer((props) => {
    const {
      itemUsers,
      itemGroups,
      checked,
      expanded,
      setChecked,
      setExpanded,
      onFilter,
      eventsFilter,
      onLoadFilter,
      setNode = null,
    } = props;
    const [filter] = useSyncFilterWithRoute(eventsFilter, onLoadFilter, true);
    const { t } = useTranslation('inputs');

    const onSearch = useCallback((search) => {
      onFilter({ search });
    }, []);

    let node = [];

    const getGroupName = (id) => {
      const res = itemGroups.find((item) => item.id === id);
      return res?.name;
    };

    itemUsers.forEach((item) => {
      if (item.groups.length) {
        item.groups.forEach((id, index) => {
          node.push({
            value: id,
            label: getGroupName(id),
            children: [{ value: `${id}_${item.id}`, label: item.username }],
          });
        });
      } else {
        node.push({
          value: item.id,
          label: item.username,
        });
      }
    });
    node = uniqBy(node, 'value');

    useEffect(() => {
      if (!!node && !!setNode) {
        setNode(node);
      }
    }, [node]);

    return (
      <>
        {itemGroups && itemUsers ? (
          <div className={'mt-10'}>
            <span className="text-1xl text-primary dark:text-white leading-9">
              {t('whoRight')}
            </span>

            <div className="flex items-center gap-4 h-[50px] mt-1">
              <InputSearch
                labelText={t('search', { ns: 'inputs' })}
                callback={onSearch}
                value={filter.search}
                className={'h-full'}
              />
            </div>

            <div className="mt-3">
              <CheckboxTree
                nodes={node}
                checked={checked}
                expanded={expanded}
                onCheck={(checked) => setChecked(checked)}
                onExpand={(expanded) => setExpanded(expanded)}
                icons={{
                  check: <IconCheck />,
                  uncheck: <IconCheck fill="#ddd" />,
                  halfCheck: <IconCheck />,
                  parentOpen: <IconGroup />,
                  parentClose: <IconGroup />,
                  leaf: <IconUser />,
                  expandClose: <IconRight />,
                  expandOpen: <IconDown />,
                }}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  }),
);

export default MultipleCheckbox;

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { queryString } from '@app/router/utils/query-params';
import { TOKEN_SORT } from '@app/utils';

export const useSyncFilterWithRoute = (
  filter,
  onUpdateFilter,
  isUpdatePath = null,
) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { query } = queryString.parseUrl(location.search);

    if (!Object.keys(query).length) return;
    let _filter = {};
    const page = query.page ? Number(query.page) : 1;
    const size = query.size ? Number(query.size) : 10;
    const sortBy = query.sortBy;
    const search = query.search;
    if (page) _filter.page = page;
    if (size) _filter.limit = size;
    if (sortBy) {
      const [id, value] = sortBy.split(TOKEN_SORT);
      if (
        id &&
        Number(value) &&
        (Number(value) === 1 || Number(value) === -1)
      ) {
        _filter.sortBy = [
          {
            id,
            desc: Number(value) === 1,
          },
        ];
      }
    }
    if (search) _filter.search = search;
    // if (Object.values(_filter).length)
    //   onUpdateFilter({ ...filter, ..._filter });
  }, []);

  useEffect(() => {
    if (!isUpdatePath) {
      const queryParams = queryString.stringify(filter);
      history.push(location.pathname + '?' + queryParams);
    }
  }, [filter]);

  return [filter];
};

import { baseApiUrl } from '@app/utils';

export const getFileByPath = async (fileUrl, token) => {
  return `${baseApiUrl()}/storage?path=${fileUrl}&access_token=${token}`;
};
export const getViolationEventZipById = (eventId, token) => {
  return `${baseApiUrl()}/api/violationRules/events/${eventId}/download?token=${token}`;
};
export const getGatesEventZipById = (eventId, token) => {
  return `${baseApiUrl()}/api/gates/events/${eventId}/download?token=${token}`;
};

import styles from './styles/Filter.module.css';

export const Filter = ({ children, isOpen, className = '' }) => {
  if (!isOpen) return null;
  return (
    <div
      className={`${
        className === 'logs'
          ? styles.logs
          : className === 'export'
          ? styles.export
          : className === 'events'
          ? styles.events
          : styles.wrapper
      } p-6 bg-white dark:bg-dark`}>
      <div className={'flex flex-col w-full h-full'}>{children}</div>
    </div>
  );
};

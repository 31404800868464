import { observer } from 'mobx-react';
import React from 'react';

import { useSlider } from '../../hooks';
import { LazyImage } from '../_other';
import { Slider } from './Slider';

export const GalleryImgSlider = observer((props) => {
  const { slides } = props;

  const [slider] = useSlider(slides);

  return (
    <div className="event-details-gallery  flex flex-col duration-300 ease-in sm:w-full md:w-2/3">
      <div className="main-img flex items-center  mb-2">
        <LazyImage
          className="w-full h-full cursor-pointer  bg-primary "
          src={slider.slides[1].url}
          loadedClass="lazy-img-loaded"
          placeholderClass="lazy-img-loading"
          errorClass="lazy-img-error"
          onClick={() => slider.showSlides(slider.slides[1].id)}
        />
      </div>
      <div className="other-img grid grid-cols-1 mt-2 sm:grid-cols-2 gap-2 w-full">
        <LazyImage
          className="w-full sm:w-1/2 h-full cursor-pointer  sm:mr-2  bg-primary "
          src={slider.slides[0].url}
          loadedClass="lazy-img-loaded"
          placeholderClass="lazy-img-loading"
          errorClass="lazy-img-error"
          onClick={() => slider.showSlides(slider.slides[0].id)}
        />
        <LazyImage
          className="w-full sm:w-1/2 mt-2 sm:mt-0 h-full  bg-primary "
          src={slides[0].url}
          loadedClass="lazy-img-loaded"
          placeholderClass="lazy-img-loading"
          errorClass="lazy-img-error"
        />
      </div>
      <Slider
        isShow={slider.isSlideShow}
        slides={slider.slides}
        setSlideOnClick={slider.setCurrentSlide}
        close={() => slider.setIsSlideShow(false)}
        currentSlide={slider.currentSlide}
        slideToPrev={slider.slideToPrev}
        slideToNext={slider.slideToNext}
      />
    </div>
  );
});

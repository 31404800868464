import { authApi } from '@app/common/api/auth';
import { socketService } from '@app/stores/_shared/socketService.module';
import { requestAPI } from '@app/utils';
import { makeAutoObservable, reaction, runInAction, when } from 'mobx';

import { storageService } from '../common';
import { pushSnackbar, tokenService } from './helpers';
import { UserModel } from './models';

const userModel = new UserModel();

export class AuthService {
  isAuth = false;

  /*
   * Возможные ключи
   * "Account", "Acl", "Camera", "Screen", "Group", "Trigger", "Logger", "Server", "Taxonomy", "CheckpointApplication",
   * "CheckpointSource", "CheckpointRoute", "CheckpointRulePerson", "CheckpointRuleVehicle", "Storage", "System",
   * "LvsPacket", "InterceptorWanted"
   *
   * с возможными значениями внутри
   * "admin",  "create", "list", "rtms", "intercept", "moderate", "upload"
   *
   * например
   * {..., Account: { ..., admin: true }}
   *
   */
  permissions = {};
  account = userModel.getUser();

  serverInfo = {};

  isLoading = false;
  token = null;

  isConnected = false;
  wrongProtocol = false;
  _apiUrl = null;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.accoundId && this.token,
      async () => {
        if (this.accoundId && (this.token || tokenService.getToken()))
          await this.accountGetCurrent();
      },
    );
    reaction(
      () => this.isAuth,
      async () => {
        if (this.isAuth) {
          await this.fetchAcl();
          await this.accountGetServerInfo();
        }
      },
    );
  }

  get accoundId() {
    return this.account.id ?? null;
  }

  get apiVersion() {
    return {
      web: window.APP_VERSION,
      api: this.serverInfo.api_version,
    };
  }

  get apiUrl() {
    return this._apiUrl;
  }

  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  setAuthStatus = (isAuth) => {
    this.isAuth = isAuth;
  };

  setAccount = (user) => {
    userModel.serialize(user);
    this.account = userModel.getUser();
  };

  setIsConnected = (isConnected) => {
    this.isConnected = isConnected;
  };

  setApi = (api) => {
    this._apiUrl = api;
  };

  logout = () => {
    tokenService.destroyToken();

    socketService.destroy();
    this.setAuthStatus(false);
    this.setIsLoading(false);
    this.setAccount({});
    this.permissions = {};
  };

  setToken = (token, store = true) => {
    if (store) {
      tokenService.setToken(token);
    }
    this.token = token;
  };

  accountLoginWithToken = async () => {
    const token = tokenService.getToken();
    if (!token) return;
    try {
      this.setIsLoading(true);
      const response = await authApi.loginWithToken({ token });

      this.setAuthStatus(true);
      this.setAccount(response.account);

      this.setIsLoading(false);
      return true;
    } catch (e) {
      console.log(e, '????');
      if (e.name === 'auth' || e.name === 're-auth') {
        this.logout();
      }
      await pushSnackbar.error('Auth-Error');

      this.setIsLoading(false);
      return false;
    }
  };

  accountLogin = async ({ name, password, api }) => {
    try {
      socketService.changeSocketUrl(api);
      const response = await authApi.loginWithCredentials({
        username: name,
        password,
      });

      console.log(response, 'response');
      this.setAuthStatus(true);
      this.setToken(response.token);

      this.setAccount(response.account);

      this.setAuthStatus(true);
      this.setApi(api);
    } catch (e) {
      console.log(e, '????');
      if (e.name === 'auth' || e.name === 're-auth') {
        this.logout();
      }
      await pushSnackbar.error('Auth-Error');
    } finally {
      this.setIsLoading(false);
    }
  };

  accountGetCurrent = async () => {
    await requestAPI({
      func: async () => authApi.accountCurrentGet(),
      onLoad: async (response) => {
        this.setAccount(response.account);
      },
      onLoadMessage: null,

      onErrorMessage: {
        message: 'Ошибка получения профиля',
      },
      onLoading: this.setIsLoading,
    });
  };

  accountGetServerInfo = async () => {
    await requestAPI({
      func: async () => authApi.accountServerInfo(),
      onLoad: async (response) => {
        this.serverInfo = response;
      },
      onLoadMessage: null,

      onErrorMessage: {
        message: 'Ошибка получения профиля',
      },
      onLoading: this.setIsLoading,
    });
  };

  checkIsLogged = async () => {
    await when(() => socketService.isConnected);
    await when(() => !this.isLoading);
    const token = tokenService.getToken();
    console.log(this.isAuth, 'checkIsLogged ????');
    if (this.isAuth && token) {
      await this.accountGetCurrent();
      await this.accountGetServerInfo();
      return true;
    }
    if (token) {
      this.setToken(token, false);
      const logged = await this.accountLoginWithToken();
      if (logged) {
        console.log(logged, '?????');
        await this.accountGetCurrent();
        await this.accountGetServerInfo();
      }
      return logged;
    }
  };

  fetchAcl = async () => {
    try {
      // Проверяем права доступа на управление
      await requestAPI({
        func: async () => authApi.accountAclGet(),
        onLoad: async (permissions) => {
          runInAction(() => {
            this.permissions = permissions;
            console.log(permissions, 'permissions ???');
          });
        },
        onLoadMessage: null,

        onErrorMessage: {
          message: 'Ошибка получения профиля',
        },
        onLoading: this.setIsLoading,
      });
    } catch (e) {
      console.error('fetch ACL', e);
    }
  };

  checkCanConnect = async (api) => {
    try {
      const res = await fetch(api + '/v2/global.ping');
      if (res.ok) {
        storageService.setItem('MS-API', api);
        this.setApi(api);
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
}

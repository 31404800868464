import { Map } from '@app/components/Map';
import { PageContainer } from '@app/layouts/PageContainer';

export const MapPage = () => {
  return (
    <PageContainer className={'flex w-full h-full'}>
      <Map />
    </PageContainer>
  );
};

import { logsApi } from '@app/common/api/logs/logs.api';
import { FilterModule } from '@app/stores/_shared/filterModule';
import { requestAPI } from '@app/utils';
import dayjs from 'dayjs';
import { escapeRegExp } from 'lodash-es';
import { makeAutoObservable } from 'mobx';

class logsStore {
  filterModule = undefined;

  list = [];
  current = {};

  isLoading = false;

  filterForm = {};

  get listTable() {
    return this.list.map((c) => {
      return {
        ...c,
        index: c.id,
        action: { id: c.id, label: c.name, can: c._can, datetime: c.datetime },
      };
    });
  }

  setIsLoading = (loading) => (this.isLoading = loading);

  clear = () => {
    this.setList([]);
    this.clearCurrent();
  };

  setList = (logsList) => (this.list = logsList);

  setCurrent = (log) => (this.current = log);
  clearCurrent = () => this.setCurrent({});

  async getFilter() {
    const filter = {};

    return filter;
  }

  getList = async ({ limit, page, sortBy, search, dateFrom, dateTo }) => {
    const params = {
      can: ['update', 'delete'],
      include: ['account'],
      limit: limit,
      offset: (page ?? 0) * (limit || 10),
      sort: {
        created_at: -1,
      },
    };
    if (sortBy) {
      params.sort = sortBy;
    }
    params.filter = await this.getFilter();

    if (search) {
      params.filter = {
        $or: [
          ...(search.match(/^[0-9a-f]{24}$/)
            ? [{ account: escapeRegExp(search) }]
            : [
                {
                  'event.name': { $regex: escapeRegExp(search), $options: 'i' },
                },
              ]),
        ],
      };
    }

    params.filter.created_at = {
      $lte: dateTo ? dayjs(dateTo).format() : dayjs().endOf('day').format(),
      $gte: dateFrom
        ? dayjs(dateFrom).format()
        : dayjs().subtract(3, 'days').startOf('day').format(),
    };
    await requestAPI({
      func: async () => await logsApi.getList({ params }),
      onLoad: (response) => {
        this.filterModule.totalItems = response.count ?? 0;
        const list = response.loggers?.map((logger, index) => {
          return {
            ...logger,
            eventName: logger.event.name,
            _can: response.can[logger.id],
          };
        });

        this.setList(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения групп пользователей ',
        callback: () =>
          this.getList({
            limit,
            page,
            sortBy,
            search,
            dateFrom,
            dateTo,
          }),
      },
      onLoading: this.setIsLoading,
    });
  };

  constructor() {
    makeAutoObservable(this);

    this.filterModule = new FilterModule(
      {
        page: 0,
        limit: 10,
        sortBy: [],
        search: '',
        dateFrom: dayjs().startOf('month').toDate(),
        dateTo: dayjs().endOf('day').toDate(),
      },
      this.getList,
    );
  }
}

export { logsStore };

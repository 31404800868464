import { onEnterKeyPressDoCallback } from '@app/utils';
import { observer } from 'mobx-react';
import React from 'react';

import { IconSuccess, IconWarning } from '../../assets';

export const SuccessSnackbar = observer((props) => {
  const { message, className, onClose, callback, callbackActionText, isShow } =
    props;

  return (
    <div
      onClick={onClose}
      onKeyDown={(e) => onEnterKeyPressDoCallback(e, onClose)}
      className={`flex flex-0  transition-all rounded-[56px] px-4 py-3 bg-success gap-10 ${
        className || ''
      } ${isShow ? 'lightSpeedInRight' : 'lightSpeedOutRight'}`}
      style={{
        animationDuration: '0.5s',
      }}>
      <button
        type={'button'}
        className="flex items-center gap-2"
        onClick={onClose}>
        <IconSuccess className={'text-white min-w-4 min-h-4 w-4 h-4'} />
        <div className="snackbar-text select-none text-left">{message}</div>
      </button>
      {callback && (
        <button
          type={'button'}
          className="snackbar-button"
          onClick={() => {
            callback();
            onClose();
          }}>
          {callbackActionText}
        </button>
      )}
    </div>
  );
});

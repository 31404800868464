import React, { forwardRef, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

export const Portal = ({ children, isShow }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (isShow) ref.current.className = 'inset-0 fixed z-top';
    else ref.current.className = 'hidden';
  }, [isShow]);
  return ReactDOM.createPortal(
    <div ref={ref}> {isShow && children}</div>,
    document.body,
  );
};

export const PortalModal = forwardRef(({ Element, isShow }, ref) => {
  useEffect(() => {
    if (!ref?.current) return;
    if (isShow) {
      ref.current.className = 'inset-0 fixed z-top';
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    } else ref.current.className = 'hidden';
  }, [isShow, ref]);
  return ReactDOM.createPortal(
    <div>
      <Transition unmountOnExit={true} nodeRef={ref} in={isShow} timeout={200}>
        {(state) => {
          return <Element state={state} />;
        }}
      </Transition>
    </div>,
    document.body,
  );
});
export const PortalNotifications = ({ children, isShow }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (isShow) ref.current.className = 'fixed z-notifications';
    else ref.current.className = 'hidden';
  }, [isShow]);
  return ReactDOM.createPortal(
    <div ref={ref}>{isShow && children}</div>,
    document.body,
  );
};

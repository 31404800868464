import { inject, observer } from 'mobx-react';
import React, { useRef } from 'react';

import { IconArrowDown } from '../../../assets';
import { useClickOutsideWrapper } from '../../../hooks';
import { PreloaderIcon } from '../../_other';
import { CallbackButton } from '../../HOC';

export const RecognizerStatus = inject(({ store }) => ({
  isOnline: store.recognizerService.isOnline,
  stopService: store.recognizerService.stopService,
  startService: store.recognizerService.startService,
  isLoading: store.recognizerService.isLoading,
}))(
  observer((props) => {
    const { isOnline, isLoading, startService, stopService } = props;

    let buttonRef = useRef(null);
    let listWrapperRef = useRef(null);
    const { isVisibleWrapper, toggleWrapperVisible, setWrapperHidden } =
      useClickOutsideWrapper(buttonRef, listWrapperRef);

    return (
      <div className={'relative'}>
        <button
          tabIndex={0}
          ref={buttonRef}
          aria-label={'Включение\\выключение процесса устройства'}
          className={`relative select-none tooltip focus:outline-black flex items-center cursor-pointer text-sm  font-medium  ${
            isOnline ? 'text-success ' : 'text-gray '
          }`}>
          <span
            key={isOnline}
            className={`select-none animate-wiggle-once uppercase`}>
            {isOnline ? 'Online' : 'Offline'}
          </span>
          <IconArrowDown className="w-6 h-6 select-none" />
          {isLoading && <PreloaderIcon className="w-8 h-8 ml-2" />}
        </button>

        <div
          className={`absolute z-10 bg-white dark:bg-bg-3 text-primary shadow-lg py-2 top-6 left-5 menu-list flex-col  ${
            isVisibleWrapper ? 'flex' : 'hidden'
          }`}
          ref={listWrapperRef}>
          <CallbackButton
            key={isOnline}
            className="w-full cursor-pointer select-none px-4 py-2 hover:bg-bg dark:hover:bg-bg-2 menu-list__item whitespace-no-wrap"
            callback={() => {
              isOnline ? stopService() : startService();
              setWrapperHidden();
            }}>
            <span>{isOnline ? 'Выключить' : 'Включить'}</span>
          </CallbackButton>
        </div>
      </div>
    );
  }),
);

import { formatTimestampByPattern } from '@app/utils';
import { inject, observer } from 'mobx-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const InterceptWantedDetailed = inject(({ store }) => ({
  isLoading: store.wantedInterceptions.isLoading,
  current: store.wantedInterceptions.current ?? {},
  itemUsers: store.users.allListTable,
  reasons: store.wantedInterceptions.reasonsList ?? [],
}))(
  observer(({ current, isLoading, itemUsers, reasons }) => {
    const { t } = useTranslation(['inputs', 'buttons', 'title']);

    let accountLib = '';
    itemUsers?.map((u) => {
      if (current?.account === u.id) accountLib = u.username;
    });

    return (
      <div
        className={`max-w-full p-4 overflow-y-auto  text-primary dark:text-white`}>
        <div className={'py-4 border-b border-b-line'}>
          <p className={'font-medium text-h1'}>
            {current?.plate_number} {isLoading ? 'Загрузка...' : ''}
          </p>
        </div>
        {current?.plate_number && (
          <Fragment>
            <div className={'py-4 border-b border-b-line'}>
              <span className={'gradient-accent text-p3 font-bold uppercase'}>
                {t('controlClassifier')}
              </span>
              <p>{reasons[current?.reason] ?? '???'}</p>
            </div>
            {current?.name && (
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('ownersName')}
                </span>
                <p>{current?.name}</p>
              </div>
            )}
            {current?.tags.length > 0 && (
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('tags', { ns: 'title' })}
                </span>
                <p className={'flex flex-col '}>
                  {current?.tags.map((item, i) => (
                    <span key={i} className={'my-2'}>
                      {item}
                    </span>
                  ))}
                </p>
              </div>
            )}
            {current?.comment && (
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('comment')}
                </span>
                <p>{current?.comment}</p>
              </div>
            )}
            {!!accountLib && (
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('author')}
                </span>
                <p>{accountLib}</p>
              </div>
            )}

            <div className={'py-4 border-b border-b-line'}>
              <span className={'gradient-accent text-p3 font-bold uppercase'}>
                {t('created')}
              </span>
              <p>
                {formatTimestampByPattern({
                  timestamp: current?.created_at,
                  pattern: 'DD-MM-YYYY HH:mm:ss',
                })}
              </p>
            </div>
          </Fragment>
        )}
      </div>
    );
  }),
);

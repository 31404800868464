import { storageService } from '@app/common';
import { authService } from '@app/stores/mainStore.store';

const currentWindow = `${window.location.protocol}//${window.location.hostname}`;
console.log(currentWindow);

export let defaultApiUrl = () =>
  !import.meta.env.DEV && import.meta.env.VITE_LOCAL_API === 'true'
    ? `${currentWindow}:4080` //:4080
    : storageService.getItem('MS-API') || import.meta.env.VITE_API_BASE_URL;

export const mapTilesUrl =
  !import.meta.env.DEV && import.meta.env.VITE_LOCAL_API === 'true'
    ? `${currentWindow}:4080/local-tiles/{z}/{x}/{y}.png`
    : import.meta.env.VITE_MAP_TILES_URL;

console.log(import.meta.env, 'ENV');

export const CURRENT_CANVAS_AREA_COLORS = {
  LINE: '#3248c1',
  CIRCLE: '#5166d7',
};

export const ACLDefaultModel = () => ({
  admin: false,
  create: false,
  view: false,
  update: false,
  delete: false,
  list: false,
});

export const IMAGES_TYPE = {
  VEHICLE: 'image_vehicle',
  PLATE: 'image_plate',
};

export const TOKEN_SORT = '|';

export const baseApiUrl = () => authService.apiUrl;

import React from 'react';

export const IconGroup = ({ className }) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.6665 2.66667C1.6665 2.29848 1.96498 2 2.33317 2H6.33317L7.99984 4H13.6665C14.0347 4 14.3332 4.29847 14.3332 4.66667V13.3333C14.3332 13.7015 14.0347 14 13.6665 14H2.33317C1.96498 14 1.6665 13.7015 1.6665 13.3333V2.66667Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.3332 7.33398H1.6665"
        stroke="#333333"
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1.6665 5.33398V9.33398"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.3335 5.33398V9.33398"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

import React, { useEffect, useRef } from 'react';

import { IconCancel } from '../../assets';
import { onEnterKeyPressDoCallback } from '@app/utils';
import { CallbackButton, Portal } from '../../components';

export const ModalDefault = (props) => {
  const { onClose, isOpen, onOpen, children, className, iconCloseClassName } =
    props;
  let modalWrapperRef = useRef(null);
  let closeIconRef = useRef(null);

  function closeOnEscKeyPressed(e) {
    if (e.keyCode === 27 && isOpen && !e.ctrlKey && !e.shiftKey && !e.altKey) {
      onClose();
    }
  }
  useEffect(() => {
    onOpen && onOpen();
    document.addEventListener('keydown', closeOnEscKeyPressed);
    return () => {
      document.removeEventListener('keydown', closeOnEscKeyPressed);
    };
  }, [isOpen]);

  const closeModal = (event) => {
    const domNodeBar = modalWrapperRef.current;
    const domCloseIconNode = closeIconRef.current;
    if (
      event.target === domCloseIconNode ||
      domCloseIconNode.contains(event.target)
    ) {
      onClose();
    } else if (
      event.target == domNodeBar ||
      domNodeBar.contains(event.target)
    ) {
      return null;
    } else {
      return onClose();
    }
  };

  return (
    <Portal isShow={isOpen}>
      <div className={`modal-window  ${isOpen ? 'flex' : 'hidden'}`}>
        <div
          className={`modal-window-box bg-white dark:bg-bg-3 ${
            className || ''
          } `}
          ref={modalWrapperRef}>
          <button
            ref={closeIconRef}
            className={`absolute w-6 h-6 cursor-pointer ${
              iconCloseClassName ? iconCloseClassName : 'top-4 right-4'
            } button-close-modal`}
            onClick={closeModal}
            onKeyPress={(e) => onEnterKeyPressDoCallback(e, closeModal)}>
            <IconCancel className="text-primary hover:text-accent" />
          </button>
          <div id="modal-children" className={'w-full h-full'}>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};

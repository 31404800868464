import { autorun, makeAutoObservable, reaction, runInAction, when } from 'mobx';

export class UiStore {
  showDrawer = {
    interceptEvent: false,
    interceptCameras: false,
    interceptWanted: false,
    imageModal: false,
  };

  setShowDrawer = (key, value) => {
    if (Object.prototype.hasOwnProperty.call(this.showDrawer, key)) {
      this.showDrawer[key] = value;
    }
  };

  openedModalId = null;
  setCurrentModalId = (modalId) => (this.openedModalId = modalId);
  closeCurrentModal = () => (this.openedModalId = null);

  notifications = [];
  setNotifications = (notifications) => (this.notifications = notifications);
  get showNotifications() {
    return this.notifications.length > 0;
  }
  get hasNotifications() {
    return (
      this.notifications.filter((n) => n.isShow === false).length ===
        this.notifications.length && this.notifications.length > 0
    );
  }

  currentNotificationIndex = 0;
  setCurrentNotificationIndex = (index) =>
    (this.currentNotificationIndex = index);

  enqueueSnackbar = ({ message, options, key }) => {
    this.notifications = [
      ...this.notifications,
      {
        message: message,
        key: key,
        ...options,
        isShow: true,
      },
    ];
  };

  pushSnackbar = async (message, options, key) => {
    if (!options.variant) {
      throw Error('pushSnackbar method need prop options.variant');
    }

    const alreadyExist = this.notifications.filter(
      (notification) => notification.key === key,
    )[0];
    const _notification = {
      message: message,
      options: {
        ...options,
        variant: options.variant,
      },
      key: alreadyExist ? `${key}--${this.notifications.length}` : key,
    };

    await this.enqueueSnackbar(_notification);
  };

  removeSnackbar = async (key, timeout) => {
    await new Promise((resolve) => {
      setTimeout(
        () =>
          runInAction(() => {
            let note = this.notifications.filter((item) => item.key === key)[0];
            if (note) note.isShow = false;
            resolve();
          }),
        timeout,
      );
    });
  };

  removeSnackbarByTimeout = () => {
    this.notifications.forEach(async (notification, index) => {
      if (!notification.isShow) return;
      let duration;
      let countShow = 0;
      this.notifications.every((notification, i) => {
        if (i < index) {
          if (notification.isShow) countShow++;
          return true;
        } else {
          return false;
        }
      });
      if (notification.callback) {
        duration = 10000 + countShow * 2000;
      } else {
        duration = 6000 + countShow * 1000;
      }
      await this.removeSnackbar(notification.key, duration);
    });
  };

  _isDarkTheme = undefined;
  get isDarkTheme() {
    return this._isDarkTheme;
  }
  _themeSelector = document.querySelector('html');
  setIsDarkTheme = (isDark) => {
    this._isDarkTheme = isDark;
  };

  checkIsDarkTheme = () => {
    this.changeTheme();
  };
  changeTheme = () => {
    if (localStorage[themeKey] === darkThemeKey) {
      this._themeSelector.classList.add('dark');
      this.setIsDarkTheme(true);
    } else if (localStorage[themeKey] === lightThemeKey) {
      this._themeSelector.classList.remove('dark');
      this.setIsDarkTheme(false);
    }
  };

  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      this.removeSnackbarByTimeout();
    });
    when(
      () => !this.hasNotifications,
      () => {
        this.setNotifications([]);
        this.setCurrentNotificationIndex(0);
      },
    );

    const globalThemeEntity = window.matchMedia('(prefers-color-scheme: dark)');
    const isThemeSaved = localStorage[themeKey];
    if (globalThemeEntity && isThemeSaved) {
      const isDarkTheme =
        globalThemeEntity.matches && isThemeSaved === darkThemeKey;
      isDarkTheme ? this.setIsDarkTheme(true) : this.setIsDarkTheme(false);

      globalThemeEntity.addEventListener('change', (e) => {
        e.matches ? this.setIsDarkTheme(true) : this.setIsDarkTheme(false);
      });
    }
    autorun(() => {
      if (this.isDarkTheme) {
        localStorage[themeKey] = darkThemeKey;
      } else {
        localStorage[themeKey] = lightThemeKey;
      }
    });

    autorun(() => {
      if (this.openedModalId || Object.values(this.showDrawer).some((v) => v)) {
        window.document.body.classList.add('overflow-hidden');
      } else {
        window.document.body.classList.remove('overflow-hidden');
      }
    });

    reaction(
      () => this.isDarkTheme,
      () => this.changeTheme(),
    );
  }
}
const themeKey = btoa('_theme');
const darkThemeKey = btoa('theme_dark');
const lightThemeKey = btoa('theme_light');

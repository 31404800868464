import { accessApi } from '@app/common/api/access';
import { FilterModule } from '@app/stores/_shared/filterModule';
import { requestAPI } from '@app/utils';
import dayjs from 'dayjs';
import { escapeRegExp } from 'lodash-es';
import { makeAutoObservable } from 'mobx';

class accessStore {
  filterModule = undefined;

  list = [];
  current = {};

  isLoading = false;

  get listTable() {
    return this.list.map((c) => {
      return {
        ...c,
        index: c.id,
        action: { id: c.id, label: c.name, can: c._can, datetime: c.datetime },
      };
    });
  }
  setIsLoading = (loading) => (this.isLoading = loading);

  clear = () => {
    this.setList([]);
    this.clearCurrent();
  };

  setList = (accessList) => (this.list = accessList);
  setCurrent = (access) => (this.current = access);
  clearCurrent = () => this.setCurrent({});

  async getFilter() {
    const filter = {};

    return filter;
  }

  getList = async ({ limit, page, sortBy, search, dateFrom, dateTo }) => {
    const params = {
      can: ['update', 'delete'],
      limit: limit,
      offset: (page ?? 0) * (limit || 10),
    };
    if (sortBy) {
      params.sort = sortBy;
    }
    params.filter = await this.getFilter();

    if (search) {
      params.filter = {
        $or: [
          ...(search.match(/^[0-9a-f]{24}$/) ? [{ id: search }] : []),
          {
            model_name: { $regex: escapeRegExp(search), $options: 'i' },
          },
          { verb_actions: { $regex: escapeRegExp(search), $options: 'i' } },
          { comment: { $regex: escapeRegExp(search), $options: 'i' } },
        ],
      };
    }

    params.filter.created_at = {
      $lte: dateTo
        ? dayjs(dateTo).startOf('minute').format()
        : dayjs().endOf('day').format(),
      $gte: dateFrom
        ? dayjs(dateFrom).startOf('minute').format()
        : dayjs().subtract(3, 'year').startOf('day').format(),
    };

    await requestAPI({
      func: async () => await accessApi.getList({ params }),
      onLoad: (response) => {
        this.filterModule.totalItems = response.count ?? 0;
        const list = response.acl?.map((acl, index) => {
          return {
            ...acl,
            _can: response.can[acl.id],
          };
        });

        this.setList(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения прав доступа ',
        callback: () => this.getList({ limit, page, sortBy }),
      },
      onLoading: this.setIsLoading,
    });
  };

  deleteItem = async (id) => {
    await requestAPI({
      func: async () => await accessApi.deleteById([id]),
      onLoad: (response) => {
        this.setList(this.list.filter((acl) => acl.id !== id));
      },
      onErrorMessage: {
        message: 'Ошибка удаления прав доступа',
        callback: () => this.deleteItem(id),
      },
      onLoadMessage: (response) => `Права доступа удалены`,
      onLoading: this.setIsLoading,
    });
  };

  addItem = async (acl) => {
    const { hasError } = await requestAPI({
      func: async () => await accessApi.create({ acl }),
      onLoad: (response) => {
        this.getList({ limit: 10, page: 0 });
      },
      onErrorMessage: {
        message: 'Ошибка добавления прав доступа ',
        callback: () => this.addItem(),
      },
      onLoadMessage: `Права доступа добавлена`,
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  getCurrentById = async (id) => {
    const { hasError } = await requestAPI({
      func: async () => await accessApi.getById({ acl: { id } }),
      onLoad: (response) => {
        this.current = response.acl;
        this.setCurrent(response.acl);
      },
      onErrorMessage: {
        message: 'Ошибка получения прав доступа',
        callback: () => this.getCurrentById(id),
      },
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  updateItem = async (acl) => {
    const { hasError } = await requestAPI({
      func: async () => await accessApi.update(acl),
      onErrorMessage: {
        message: 'Ошибка обновления прав доступа ',
        callback: () => this.updateItem(),
      },
      onLoadMessage: `Права доступа обновлены`,
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  constructor() {
    makeAutoObservable(this);

    this.filterModule = new FilterModule(
      {
        page: 0,
        limit: 10,
        sortBy: [],
        search: '',
        dateFrom: dayjs().startOf('month').toDate(),
        dateTo: dayjs().endOf('day').toDate(),
      },
      this.getList,
    );
  }
}

export { accessStore };

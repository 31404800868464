import React from 'react';
export const IconEye = ({ isOpen, className }) => (
  <svg
    className={` ${className || ''}`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C7.45455 5 3.57273 7.82727 2 11.8182C3.57273 15.8091 7.45455 18.6364 12 18.6364C16.5455 18.6364 20.4273 15.8091 22 11.8182C20.4273 7.82727 16.5455 5 12 5ZM12 16.3636C9.49092 16.3636 7.45455 14.3273 7.45455 11.8182C7.45455 9.30908 9.49092 7.27272 12 7.27272C14.5091 7.27272 16.5455 9.30908 16.5455 11.8182C16.5455 14.3273 14.5091 16.3636 12 16.3636ZM9.27273 11.8181C9.27273 10.309 10.4909 9.09087 12 9.09087C13.5091 9.09087 14.7273 10.309 14.7273 11.8181C14.7273 13.3272 13.5091 14.5454 12 14.5454C10.4909 14.5454 9.27273 13.3272 9.27273 11.8181Z"
      fill="#A7ABB4"
    />
    <path
      style={isOpen ? { opacity: 1 } : { opacity: 0 }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.38478 5.20378C4.05887 4.87787 4.05887 4.34948 4.38478 4.02358C4.71068 3.69768 5.23907 3.69768 5.56497 4.02358L20.4941 18.9527C20.82 19.2786 20.82 19.807 20.4941 20.1329C20.1682 20.4588 19.6398 20.4588 19.3139 20.1329L4.38478 5.20378Z"
      fill="#A7ABB4"
    />
  </svg>
);

import { cameraApi } from '@app/common/api/camera';
import { FilterModule } from '@app/stores/_shared/filterModule';
import { requestAPI } from '@app/utils';
import { escapeRegExp, find } from 'lodash-es';
import { makeAutoObservable } from 'mobx';

class camerasStore {
  filterModule = undefined;
  filterModuleAll = undefined;
  filterForm = {};
  list = [];
  allList = [];
  lib = [];
  isLoading = false;
  isMapSourcesEnabled = false;

  setIsMapSourcesEnabled = (s) => (this.isMapSourcesEnabled = s);

  get listTable() {
    return this.list.map((c) => {
      return {
        ...c,
        index: c.id,
        action: { id: c.id, label: c.name, can: c._can },
      };
    });
  }
  get allListTable() {
    if (!this.isMapSourcesEnabled) return [];
    return this.allList.map((c) => {
      return {
        ...c,
        index: c.id,
        action: { id: c.id, label: c.name, can: c._can },
      };
    });
  }
  setList = (camerasList) => (this.list = camerasList);
  setAllList = (camerasList) => (this.allList = camerasList);
  setLib = (lib) => (this.lib = lib);

  async getFilter() {
    const filter = {};
    return filter;
  }

  getAllList = async () => {
    const params = {
      can: ['update', 'delete'],
      limit: 999999999,
      // offset: (page ?? 0) * (limit || 10),
    };

    await requestAPI({
      func: async () => await cameraApi.getList({ params }),
      onLoad: (response) => {
        const list = response.cameras?.map((camera, index) => {
          return {
            ...camera,
            _can: response.can[camera.id],
          };
        });
        const lib = response.cameras?.map((camera) => {
          return {
            value: camera.id,
            label: camera.name,
          };
        });
        this.setAllList(list);
        this.setLib(lib);
      },
      onErrorMessage: {
        message: 'Ошибка получения пользователей ',
        callback: () => this.getAllList(),
      },
      onLoading: this.setIsLoading,
    });
  };

  getList = async ({ limit, page, sortBy, search }) => {
    const params = {
      can: ['update', 'delete'],
      include: ['account', 'camera'],
      limit: limit,
      offset: (page ?? 0) * (limit || 10),
    };
    if (sortBy) {
      params.sort = sortBy;
    }
    params.filter = await this.getFilter();

    if (search) {
      params.filter = {
        $or: [
          ...(search.match(/^[0-9a-f]{24}$/) ? [{ id: search }] : []),
          { name: { $regex: escapeRegExp(search), $options: 'i' } },
          { comment: { $regex: escapeRegExp(search), $options: 'i' } },
          ...(search.match(/[A-Za-zА-яа-я!@#$%^&*)\(«»+=_-]/)
            ? []
            : [
                { 'latlng.0': { $gte: search, $lte: search } },
                { 'latlng.1': { $gte: search, $lte: search } },
              ]),
        ],
      };
    }

    requestAPI({
      func: async () => await cameraApi.getList({ params }),
      onLoad: (response) => {
        this.filterModule.totalItems = response.count ?? 0;
        const list = response.cameras?.map((camera, index) => {
          return {
            ...camera,
            // eventName: camera.event.name,
            _can: response.can[camera.id],
          };
        });
        this.setList(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения камер',
        callback: () =>
          this.getList({
            limit,
            page,
            sortBy,
            search,
          }),
      },
      onLoading: this.setIsLoading,
    });
  };

  constructor() {
    makeAutoObservable(this);
    this.filterModule = new FilterModule(
      {
        page: 0,
        limit: 10,
        sortBy: [],
        search: '',
      },
      this.getList,
    );

    this.filterModuleAll = new FilterModule(
      {
        page: 0,
        limit: 10,
      },
      this.getAllList,
    );
  }
}

export { camerasStore };

import React from 'react';
export const IconCross = ({ className }) => (
  <svg
    className={`icon-cross fill-current ${className}`}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M2.80631 2.92506L2.86177 2.86225C3.10209 2.62193 3.48024 2.60345 3.74177 2.8068L3.80457 2.86225L7.99984 7.05699L12.1951 2.86225C12.4554 2.6019 12.8776 2.6019 13.1379 2.86225C13.3983 3.1226 13.3983 3.54471 13.1379 3.80506L8.94317 8.00033L13.1379 12.1956C13.3782 12.4359 13.3967 12.8141 13.1934 13.0756L13.1379 13.1384C12.8976 13.3787 12.5194 13.3972 12.2579 13.1939L12.1951 13.1384L7.99984 8.94366L3.80457 13.1384C3.54423 13.3987 3.12212 13.3987 2.86177 13.1384C2.60142 12.878 2.60142 12.4559 2.86177 12.1956L7.0565 8.00033L2.86177 3.80506C2.62144 3.56474 2.60296 3.18659 2.80631 2.92506L2.86177 2.86225L2.80631 2.92506Z" />
  </svg>
);

import React from 'react';

export const IconDown = (props) => {
  const { className } = props;
  return (
    <svg
      className={`fill-current ${className || ''}`}
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L7 7L13 1" stroke="#333333" strokeWidth="2" fill="none" />
    </svg>
  );
};

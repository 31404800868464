import { MapPage } from '@app/pages/MapPage';
import { NotFoundPage } from '@app/pages/NotFound';
import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import { RoutesAccess } from './RoutesAccess';
import { RoutesCameras } from './RoutesCameras';
import { RoutesEvents } from './RoutesEvents';
import { RoutesGroup } from './RoutesGroups';
import { RoutesLogs } from './RoutesLogs';
import { RoutesReports } from './RoutesReports';
import { RoutesWanted } from './RoutesWanted';
import { RoutesUsers } from './RouteUsers';

export function Routes() {
  return (
    <Fragment>
      <Switch>
        <Route path={'/wanted'} component={RoutesWanted} />
        <Route path={'/events'} component={RoutesEvents} />
        <Route path={'/cameras'} component={RoutesCameras} />
        <Route path={'/groups'} component={RoutesGroup} />
        <Route path={'/logs'} component={RoutesLogs} />
        <Route path={'/users'} component={RoutesUsers} />
        <Route path={'/acl'} component={RoutesAccess} />
        <Route path={'/reports'} component={RoutesReports} />
        <Route path={'/'} component={MapPage} />
        <Route path={'*'} component={NotFoundPage} />
      </Switch>
    </Fragment>
  );
}

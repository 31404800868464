import React from 'react';

export const IconWarning = ({ className }) => {
  return (
    <svg
      className={`fill-current  ${className || ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5129 18.0284L15.0254 2.40313C14.3848 1.32473 13.2535 0.680664 11.9991 0.680664C10.7447 0.680664 9.61333 1.32473 8.97274 2.40313C8.96791 2.41138 8.96383 2.41963 8.959 2.42788L0.499754 18.0036C-0.154574 19.1048 -0.166949 20.4272 0.466051 21.5407C1.1005 22.6549 2.2435 23.3195 3.52539 23.3195H20.4259C21.7078 23.3195 22.8978 22.6549 23.5322 21.5407C24.1652 20.4272 24.1528 19.1047 23.5129 18.0284ZM10.5914 7.83515C10.5914 7.05768 11.2217 6.42749 11.9991 6.42749C12.7766 6.42749 13.4067 7.05773 13.4067 7.83515V13.4658C13.4067 14.2432 12.7765 14.8735 11.9991 14.8735C11.2217 14.8735 10.5914 14.2431 10.5914 13.4658V7.83515ZM11.9991 20.5042C10.8348 20.5042 9.88755 19.557 9.88755 18.3927C9.88755 17.2283 10.8347 16.2812 11.9991 16.2812C13.1634 16.2812 14.1106 17.2283 14.1106 18.3927C14.1106 19.557 13.1635 20.5042 11.9991 20.5042Z"
        fill="#FF9A24"
      />
    </svg>
  );
};

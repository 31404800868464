import { socketService } from '../../..//stores/_shared/socketService.module';

export const groupsApi = {

  getListCamera: ({ params }) => {
    return socketService.emit({
      method: 'camera.list',
      params,
    });
  },

  getList: ({ params }) => {
    return socketService.emit({
      method: 'group.list',
      params,
    });
  },

  getById: (params) => {
    return socketService.emit({
      method: 'group.get',
      params,
    });
  },

  getByIds: (params) => {
    return socketService.bulk({
      method: 'group.get',
      params: params.map((id) => ({ group: { id } })),
    });
  },

  /**
   * @param {Array<Number>} params - array of ids to delete
   * @returns Promise
   */
  deleteById: (params) => {
    return socketService.bulk({
      method: 'group.delete',
      params: params.map((id) => ({ group: { id } })),
    });
  },
  update: (group) => {
    return socketService.emit({
      method: 'group.update',
      params: { group },
    });
  },
  create: ({ group }) => {
    return socketService.emit({
      method: 'group.create',
      params: { group },
    });
  },
};

import { AccessIcon } from '@asset/icons/svgJsIcons/Sidebar/AccessIcon';
import { CameraIcon } from '@asset/icons/svgJsIcons/Sidebar/CameraIcon';
import { EventIcon } from '@asset/icons/svgJsIcons/Sidebar/EventIcon';
import { GroupsIcon } from '@asset/icons/svgJsIcons/Sidebar/GroupsIcon';
import { MapIcon } from '@asset/icons/svgJsIcons/Sidebar/MapIcon';
import { LogsIcon } from '@asset/icons/svgJsIcons/Sidebar/LogsIcon';
import { UsersIcon } from '@asset/icons/svgJsIcons/Sidebar/UsersIcon';
import { WantedIcon } from '@asset/icons/svgJsIcons/Sidebar/WantedIcon';
import { useTranslation } from 'react-i18next';
import { NavLink as Link } from 'react-router-dom';
import { ReportsIcon } from '@asset/icons/svgJsIcons/Sidebar/ReportsIcon';

export const NavLink = ({ path, exact, labelText }) => {
  const { t } = useTranslation('sidebar');
  const label = path.split('/').join('');
  const iconClass = 'w-9 h-7';
  const iconsObj = {
    map: <MapIcon className={iconClass} />,
    events: <EventIcon className={iconClass} />,
    wanted: <WantedIcon className={iconClass} />,
    cameras: <CameraIcon className={iconClass} />,
    users: <UsersIcon className={iconClass} />,
    groups: <GroupsIcon className={iconClass} />,
    acl: <AccessIcon className={iconClass} />,
    logs: <LogsIcon className={iconClass} />,
    reports: <ReportsIcon className={iconClass} />,
  };
  return (
    <Link
      exact={exact}
      to={path}
      className={`
         transition-all duration-300 ease-[cubic-bezier(1, -0.27, 1, 1)]
         px-3 w-full max-w-full
         flex items-center py-3 dark:text-white
         font-medium header-nav-link 
         hover:bg-bg rounded-r-[56px] 
         dark:hover:bg-bg-2 rounded-r-[56px]`}
      activeClassName={'text-white bg-gradient-accent rounded-r-[56px]'}>
      <div className="flex space-x-1 items-center cursor-pointer">
        {label.length === 0 ? iconsObj['map'] : iconsObj[label]}
        <span>{t(`${label.length === 0 ? 'map' : label}`)}</span>
      </div>
    </Link>
  );
};

import { formatTimestampByPattern } from '@app/utils';
import { inject, observer } from 'mobx-react';
import { useCallback } from 'react';

import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { ReactComponent as NotificationIcon } from '../icons/notification.svg';
import styles from './Card.module.css';

export const InterceptCamerasDetailed = inject(({ store }) => ({
  current: store.eventsInterceptions.mapEvents,
  notifyRead: store.eventsInterceptions.notifyRead,
  getCurrentEvent: store.eventsInterceptions.getCurrentById,
  onOpenDrawer: (value) => store.ui.setShowDrawer('interceptEvent', value),
}))(
  observer(({ current, notifyRead, getCurrentEvent, onOpenDrawer }) => {
    const onViewDetailed = useCallback(
      ({ id, datetime }) =>
        async () => {
          notifyRead(id);
          console.log(datetime);
          getCurrentEvent({ id, datetime });
          await onOpenDrawer(true);
        },
      [],
    );
    // const commentsList = current.event?.comment
    //   ? current.event?.comment?.split(';') || [current.event?.comment]
    //   : [];
    return (
      <div
        className={`max-w-full overflow-y-auto  text-primary dark:text-white`}>
        {current.map((card) => {
          return (
            <button
              onClick={onViewDetailed(card)}
              className={`${styles.card} group`}
              key={card.id}>
              <div className={styles.notification}>
                {card.notify && <NotificationIcon />}
              </div>
              <div className={styles.content}>
                <div className={styles.date}>
                  {formatTimestampByPattern({
                    timestamp: card.datetime,
                    pattern: 'DD/MM/YYYY HH:mm:ss',
                  })}
                </div>
                <div className={styles.number}>{card.plateNum}</div>
                <div className={styles.description}>{card.description}</div>
              </div>
              <div
                className={`${styles.arrow} 
                text-primary dark:text-white 
                group-hover:text-accent group-focus:text-accent`}>
                <ArrowRight className={'stroke-current'} />
              </div>
            </button>
          );
        })}
        {current.length === 0 && (
          <div className={styles.empty}>Нет результатов</div>
        )}
      </div>
    );
  }),
);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import importedMessagesEN from '../assets/locales/en/index';
import importedMessagesRU from '../assets/locales/ru/index';
import importedMessagesKZ from '../assets/locales/kz/index';


const loadLocaleMessages = (importedMessages) => {
  const locales = Object.keys(importedMessages);

  const messages = {};
  locales.forEach((key) => {
    messages[key] = importedMessages[key];
  });
  return messages;
};

const resources = {
  en: loadLocaleMessages(importedMessagesEN),
  ru: loadLocaleMessages(importedMessagesRU),
  kz: loadLocaleMessages(importedMessagesKZ),
};

i18n.use(initReactI18next).init({
  fallbackLng: 'ru',
  resources,
});

export default i18n;

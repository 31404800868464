import { useLocalObservable } from 'mobx-react-lite';

export const useSlider = (slides) => {
  const slider = useLocalObservable(() => ({
    isSlideShow: null,
    currentSlide: null,
    get slides() {
      return slides.filter((slide) => !slide.isHidden);
    },
    setIsSlideShow(value) {
      this.isSlideShow = value;
    },
    setCurrentSlide(value) {
      this.currentSlide = value;
    },
    slideToPrev() {
      const currentSlideIndex = this.slides.findIndex(
        (slide) => slide.id === this.currentSlide,
      );
      if (currentSlideIndex === 0) {
        this.setCurrentSlide(this.slides[this.slides.length - 1].id);
      } else {
        this.setCurrentSlide(this.slides[currentSlideIndex - 1].id);
      }
    },
    slideToNext() {
      const currentSlideIndex = this.slides.findIndex(
        (slide) => slide.id === this.currentSlide,
      );
      if (this.currentSlide === this.slides[this.slides.length - 1].id) {
        this.setCurrentSlide(this.slides[0].id);
      } else {
        this.setCurrentSlide(this.slides[currentSlideIndex + 1].id);
      }
    },
    showSlides(slideId) {
      this.setCurrentSlide(slideId);
      this.setIsSlideShow(true);
    },
  }));
  return [slider];
};

import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { getFileByPath } from '../../../hooks';
import { InputFile } from './InputFile';

export const InputImageWithUpload = inject(({ store }) => ({
  uploadFile: store.foldersService.uploadFile,
  loading: store.foldersService.isLoading,
  token: store.auth.token,
}))(
  observer((props) => {
    const { imagePath, token, uploadFile, loading, onChangeCallback } = props;
    const [image, setImage] = useState(null);
    const onUpload = async (file) => {
      try {
        if (file) {
          const response = await uploadFile(file);
          onChangeCallback(response.filePath);
          setImage(response.filePath);
        } else {
          onChangeCallback('');
          setImage('');
        }
      } catch (e) {}
    };
    useEffect(() => {
      try {
        getFileByPath(imagePath, token).then((imageUrl) => {
          if (imagePath) setImage(imageUrl);
        });
      } catch (e) {
        console.log(e);
      }
    }, [imagePath]);
    return (
      <InputFile
        {...props}
        onUpload={onUpload}
        fileValue={image}
        isLoading={loading}
      />
    );
  }),
);

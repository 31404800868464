export const CameraIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75254 10.9473V15.4157H2.91675"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.592 8.83958L16.9821 8.4082L15.3145 11.4123L17.7293 12.0593L18.592 8.83958Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.982 8.40833L15.3143 11.4124L14.5883 12.512L2.91675 9.38458L4.42653 3.75L17.708 7.30875L16.982 8.40833Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

// /locales/en/index.ts
//получаем все переводы из папки /locales/en
export const importModuleComponents = import.meta.globEager('./*.json');

// закидываем модуль и имя локали в массив
const languageModules = Object.keys(importModuleComponents).reduce((acc, filepath) => {
  const moduleItem = importModuleComponents[filepath].default;
  //генерим имя локали на основе пути компонента
  const moduleName = filepath.replace(/(\.\/|\.json)/g, '');
  return { ...acc, [moduleName]: moduleItem };
}, {});

export default languageModules;

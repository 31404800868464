import { observer } from 'mobx-react';
import React from 'react';

import { useSlider } from '../../hooks';
import { LazyImage } from '../_other';
import { Slider } from './Slider';

export const GalleryImgSliderSmall = observer((props) => {
  const { slides } = props;

  const [slider] = useSlider(slides);

  return (
    <div className="event-details-gallery mt-4 sm:mt-0 small  flex flex-col duration-300 ease-in w-full md:w-2/3">
      <div className="main-img grid grid-cols-1 sm:grid-cols-2  gap-2 mb-2">
        {slider.slides.map((slide) => {
          return (
            <LazyImage
              key={slide.url}
              className={`w-full  h-full cursor-pointer  bg-primary `}
              src={slide.url}
              loadedClass="lazy-img-loaded"
              placeholderClass="lazy-img-loading"
              errorClass="lazy-img-error"
              onClick={() => slider.showSlides(slide.id)}
            />
          );
        })}
      </div>
      <Slider
        isShow={slider.isSlideShow}
        slides={slider.slides}
        setSlideOnClick={slider.setCurrentSlide}
        close={() => slider.setIsSlideShow(false)}
        currentSlide={slider.currentSlide}
        slideToPrev={slider.slideToPrev}
        slideToNext={slider.slideToNext}
      />
    </div>
  );
});

import { debounce } from 'lodash-es';
import { memo, useEffect, useRef } from 'react';

import { IconCross, IconSearch } from '../../../assets';

export const InputSearch = memo((props) => {
  const {
    callback,
    onClearInput,
    className,
    value,
    labelText,
    disabled = false,
    debounceMs = 500,
    children,
    ...rest
  } = props;

  const ref = useRef(null);
  if (!labelText) {
    throw Error('Нужен labelText для инпута :)');
  }

  useEffect(() => {
    if (typeof value === 'string') {
      ref.current.value = value;
    }
  }, [value]);

  const onChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!callback) {
      throw Error('Нужен колбэк для инпута :)');
    }
    const inputValue = e.target.value;
    callback(inputValue);
  };

  const debounced = debounce(onChange, debounceMs);

  const onClear = () => {
    ref.current.value = '';
    onClearInput && onClearInput('');
    callback('');
  };

  return (
    <div
      className={`relative input-search__wrap flex items-center ${
        className ?? ''
      }`}>
      <div className="absolute left-4 top-0 bottom-0 flex items-center">
        <IconSearch className={'text-gray w-6 h-6'} />
      </div>
      <input
        {...rest}
        disabled={disabled}
        placeholder={labelText}
        ref={ref}
        defaultValue={value}
        onInput={debounced}
        className={`
        ${children ? 'w-[calc(100%_+_40px]' : 'w-full'}
          dark:bg-bg-3
          border
          border-border
          dark:border-secondary
          transition
          duration-200
          hover:border-gray
          dark:hover:border-bg-3
          focus:border-secondary
          rounded-[56px] text-reg
          placeholder-current text-primary
          dark:text-white
          px-12
          ${children ? 'pr-16' : 'pr-12'}
          h-full
          input-text`}
      />
      <div
        className={`absolute iconSearch text-gray dark:text-bg dark:hover:text-accent items-center justify-center ${
          children ? 'right-10' : 'right-6'
        } top-0 bottom-0 w-6 h-full ${value ? 'flex' : 'hidden'}`}>
        <button
          className={'focus:outline-black'}
          type={'button'}
          onClick={onClear}>
          <IconCross className="" />
        </button>
      </div>
      {children}
    </div>
  );
});

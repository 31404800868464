import { hasPermission } from '@app/stores/helpers';
import { computed } from 'mobx';

export const navBarLinksData = computed(() => [
  {
    label: 'Карта',
    path: '/',
    exact: true,
    permission:
      hasPermission(['InterceptorWanted.admin', 'Camera.admin']) ||
      hasPermission(['InterceptorWanted.list', 'Camera.intercept']),
  },
  {
    label: 'События',
    path: '/events',
    permission:
      hasPermission('Camera.admin') || hasPermission('Camera.intercept'),
  },
  {
    label: 'Источники',
    path: '/cameras',
    permission: hasPermission('Camera.admin') || hasPermission('Camera.list'),
  },
  {
    label: 'Список ориентировок',
    path: '/wanted',
    permission:
      hasPermission(['InterceptorWanted.admin', 'Camera.admin']) ||
      hasPermission(['InterceptorWanted.list', 'Camera.intercept']),
  },
  {
    label: 'Пользователи',
    path: '/users',
    permission: hasPermission('Account.admin') || hasPermission('Account.list'),
  },
  {
    label: 'Группы пользователей',
    path: '/groups',
    permission: hasPermission('Group.admin') || hasPermission('Group.list'),
  },
  {
    label: 'Права доступа',
    path: '/acl',
    permission: hasPermission('Acl.admin') || hasPermission('Acl.list'),
  },
  // {
  //   label: 'Отчеты и статистика',
  //   path: '/statistics',
  //   permission: true,
  // },
  {
    label: 'Логи',
    path: '/logs',
    permission: hasPermission('Logger.admin') || hasPermission('Logger.list'),
  },
  {
    label: 'Отчеты',
    path: '/reports',
    permission: hasPermission('Camera.admin') || hasPermission('Camera.list'),
  },
]);

import './assets/fonts/NotoSansJP-Regular-Alphabetic.woff';
import './assets/fonts/NotoSansJP-Regular-Alphabetic.woff2';
import './assets/fonts/NotoSansJP-Medium-Alphabetic.woff';
import './assets/fonts/NotoSansJP-Medium-Alphabetic.woff2';
import './index.css';
import './assets/styles/main.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { version } from '../package.json';
import App from './App';
import { PreloaderIcon } from './components';

declare global {
  interface Window {
    APP_VERSION: string;
  }
}

window.APP_VERSION = version;

const Loader = () => (
  <div className="w-full h-full flex items-center justify-center">
    <PreloaderIcon className="w-24 h-24 text-accent" />
  </div>
);

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
  document.getElementById('root'),
);

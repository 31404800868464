import { BaseSelectMultiple } from '@component/ui';
import { t } from 'i18next';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';

export const ReasonsWantedSelect = inject(({ store }) => ({
  isLoading: store.wantedInterceptions.isLoading,
  options: store.wantedInterceptions.reasons ?? [],
  getLib: store.wantedInterceptions.getLib,
}))(
  observer((props) => {
    const {
      callback,
      placeholder = t('reason', { ns: 'inputs' }),
      value,
      label,
      classNameLabel,
      className,
      inputId,
      isLoading,
      options,
      getLib,
      hasSelectAll,
    } = props;

    useEffect(() => {
      if (!options.length) {
        getLib();
      }
    }, [options]);
    return (
      <>
        <BaseSelectMultiple
          value={value}
          classNameLabel={classNameLabel}
          placeholder={placeholder}
          label={label}
          inputId={inputId || Math.round(19)}
          className={className}
          options={options}
          isLoading={isLoading}
          callback={callback}
          hasSelectAll={hasSelectAll}
        />
      </>
    );
  }),
);

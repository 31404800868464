import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
export const InputNumber = observer(
  ({
    type,
    callback,
    fractionsDigits = 0,
    defaultValue,
    className,
    labelText,
    textError,
    hasErrors,
  }) => {
    const ref = useRef(null);
    const [inputValue, setInputValue] = useState('');
    if (!labelText) {
      throw Error('Нужен labelText для инпута :)');
    }
    if (!inputFormatter) {
      throw Error('Нужена функция inputFormatter для инпута :)');
    }
    const decimalRegExp = /^[0]?[0-9]+[.]?[0-9]+/;
    const isEmpty = (str) => (str.trim() == '' ? true : false);

    function inputFormatter(inputValue) {
      let value;

      // и без пробелов
      value = inputValue.replace(/\s+/g, '');
      value = value.split(' ').join('');
      value.replace(decimalRegExp, '');
      // только одна точка
      value = value.replace(/(^[^.]*.)|[.]+/gi, '$1');
      // и только цифры
      value = value.replace(/[^\d.]/g, '');
      value = value.replace(/[^\d.]/g, '');

      if (!value || isEmpty(value)) return '';
      return value;
    }

    const onChange = (e) => {
      if (!callback) {
        throw Error('Нужен колбэк для инпута :)');
      }
      const formatedValue = inputFormatter(e.target.value);
      setInputValue(formatedValue);
      // надо для проверки значения на null инпута, чтобы в css контролировать label
      ref.current.setAttribute('data-value', formatedValue);
      callback(formatedValue);
    };
    const onBlur = (e) => {
      if (!callback) {
        throw Error('Нужен колбэк для инпута :)');
      }
      let formatedValue;
      if (e.target.value) {
        formatedValue = fractionsDigits
          ? Number(e.target.value).toFixed(fractionsDigits)
          : parseFloat(e.target.value);
      } else {
        formatedValue = 0;
      }
      setInputValue(formatedValue);
      // надо для проверки значения на null инпута, чтобы в css контролировать label
      ref.current.setAttribute('data-value', formatedValue);
      callback(formatedValue);
    };
    useEffect(() => {
      if (defaultValue || defaultValue === 0) {
        setInputValue(defaultValue);
        ref.current.value = defaultValue;
        ref.current.setAttribute('data-value', defaultValue);
      }
    }, [defaultValue]);

    return (
      <div
        className={`relative input-text__wrap  ${className ? className : ''} ${
          hasErrors ? 'error' : ''
        }`}>
        <input
          type={type}
          value={inputValue ?? ''}
          ref={ref}
          onBlur={onBlur}
          onChange={onChange}
          data-value={inputValue ?? ''}
          className={`w-full bg-bg 
          
              dark:bg-bg-2
              border-b 
              border-border 
              focus:border-accent
              rounded-t-md text-reg 
              text-primary
               
              dark:text-white
              pl-4 pr-4 pt-6 pb-2
              input-text `}
        />
        <span className="bar" />
        <label className="absolute pointer-events-none text-gray duration-200 ease">
          {labelText}
        </label>
        {hasErrors && <div className="text-error text-sm">{textError}</div>}
      </div>
    );
  },
);

export const CallbackButton = ({ callback, children, _ref, ...props }) => {
  return (
    <button
      onClick={(e) => {
        if (!e.isDefaultPrevented()) callback(e);
      }}
      ref={_ref || null}
      {...props}>
      {children}
    </button>
  );
};

import { accessStore } from './accessStore';
import { AuthService } from './authService';
import { camerasStore } from './camerasStore';
import { eventsInterceptionsStore } from './eventsInterceptionsStore';
import { groupsStore } from './groupsStore';
import { logsStore } from './logsStore';
import { reportsInterceptionsStore } from './reportsInterceptionsStore';
import { UiStore } from './uiStore';
import { usersStore } from './usersStore';
import { wantedInterceptionsStore } from './wantedInterceptionsStore';

export const uiService = new UiStore();
export const authService = new AuthService();

const wantedInterceptions = new wantedInterceptionsStore();
const eventsInterceptions = new eventsInterceptionsStore();
const camerasInterceptions = new camerasStore();
const groupsInterceptions = new groupsStore();
const logsInterceptions = new logsStore();
const reportsInterceptions = new reportsInterceptionsStore();
const users = new usersStore();
const access = new accessStore();

export const store = (window.store = (() => ({
  ui: uiService,
  auth: authService,
  wantedInterceptions,
  eventsInterceptions,
  camerasInterceptions,
  groupsInterceptions,
  logsInterceptions,
  users,
  access,
  reportsInterceptions,
}))());
export const getStoreListeners = () => [store.eventsInterceptions.onEvent];

export const isAdmin = (values) => {
  const result = Object.keys(values).some((i) => values[i] === 'admin');
  if (result) {
    return result;
  }
  return Object.keys(values).filter((item) => {
    if (values['role'] !== 'admin') {
      if (values[item] === true) {
        return item;
      }
    }
  });
};
export const isCorrect = (checked, role) => {
  if (!!checked.length) {
    if (typeof role === 'boolean' && role) {
      return true;
    }
    if (Array.isArray(role) && !!role.length) {
      return true;
    }
  }
  return false;
};

export const isCameraValues = (values) => {
  return Object.keys(values).reduce((acc, item) => {
    const cameraName = item.split(' ');
    if (cameraName[0] === 'camera' && values[item]) {
      acc.push(cameraName[1]);
    }
    return acc;
  }, []);
};

import { socketService } from '../../..//stores/_shared/socketService.module';

export const logsApi = {
  getList: ({ params }) => {
    return socketService.emit({
      method: 'logger.list',
      params,
    });
  },

  /**
   * @param {Array<Number>} params - array of ids to delete
   * @returns Promise
   */
};

import React from 'react';

import { InputSelect } from './ui';
import { useTranslation } from 'react-i18next';

export const SelectLanguage = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const { t } = useTranslation(['inputs', 'title']);
  return (
    <InputSelect
      options={[
        { value: 'ru', label: t('ru', { ns: 'title' }) },
        { value: 'kz', label: t('kz', { ns: 'title' }) },
        { value: 'en', label: t('en', { ns: 'title' }) },
      ]}
      type={'text'}
      labelText={t('lang')}
      callback={changeLanguage}
      defaultValue={i18n.language}
    />
  );
};

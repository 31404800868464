import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconEye } from '../../../assets';
import { CallbackButton } from '../../HOC';

export const InputPassword = ({
  callback,
  hasError,
  className,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation('inputs');

  const [isOpenEye, setOpenEye] = useState(false);
  const inputRef = useRef(null);
  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!callback) {
      throw Error('Нужен колбэк для инпута :)');
    }
    // надо для проверки значения на null инпута, чтобы в css контролировать label
    e.target.setAttribute('data-value', e.target.value);
    callback(e.target.value);
  };
  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
  }, [isOpenEye]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      inputRef.current.value = defaultValue;
      inputRef.current.setAttribute('data-value', defaultValue);
    }
  }, [defaultValue]);

  const toggleOpenEye = () => {
    setOpenEye(!isOpenEye);
    inputRef.current.focus();
  };
  return (
    <div
      className={`relative input-text__wrap ${hasError ? 'error' : ''}  ${
        className ? className : ''
      }`}>
      <input
        type={isOpenEye ? 'text' : 'password'}
        ref={inputRef}
        autoComplete={isOpenEye ? 'current-password' : 'off'}
        defaultValue={value}
        onChange={onChange}
        data-value={value}
        className={`w-full
              border-b 
              border-b-line 
              focus:border-b-accent 
              text-reg 
              text-primary
              dark:text-white
              dark:bg-bg-3
               pl-4 pr-10 pt-6 pb-2
              input-text 
              `}
      />
      <span className="bar" />
      <label className="absolute pointer-events-none  text-gray dark:text-primary  duration-200 ease">
        {t('password')}
      </label>
      <CallbackButton
        type={'button'}
        className="eyeIcon w-6 h-full flex items-center absolute  cursor-pointer justify-center focus:outline-black"
        callback={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleOpenEye();
        }}>
        <IconEye isOpen={isOpenEye} />
      </CallbackButton>
    </div>
  );
};

import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { IconArrowLeft } from '../../assets';
import { onEnterKeyPressDoCallback } from '@app/utils';

import { LazyImage } from '../_other';
import { ModalSlider } from '../Modals';

const SliderDots = observer(({ info }) => {
  const { slides, currentSlide, setSlideOnClick } = info;
  return (
    <div className="slider-dots   flex items-center duration-300 h-3 w-16">
      {slides?.map((item, i) => {
        const isCurrent = currentSlide === item.id;

        return (
          <div
            key={item.url + i}
            id={`dot-${i}`}
            onKeyDown={(e) =>
              onEnterKeyPressDoCallback(e, setSlideOnClick(item.id))
            }
            onClick={() => setSlideOnClick(item.id)}
            className={`dots ${slides.length - 1 !== i ? 'mr-2' : ''} ${
              isCurrent ? 'active-dot' : ''
            }`}
          />
        );
      })}
    </div>
  );
});
export const Slider = observer(
  ({
    isShow,
    close,
    currentSlide,
    slideToPrev,
    slideToNext,
    slides,
    setSlideOnClick,
  }) => {
    const slideTo = (e) => {
      e.preventDefault();
      e.stopPropagation();
      switch (e.key) {
        // case "ArrowUp":
        //   break;
        case 'ArrowDown':
          close();
          break;
        case 'ArrowLeft':
          slideToPrev();
          break;
        case 'ArrowRight':
          slideToNext();
      }
    };
    useEffect(() => {
      window.addEventListener('keyup', slideTo);
      return () => {
        window.removeEventListener('keyup', slideTo);
      };
    });
    return (
      <ModalSlider isOpen={isShow} onClose={close}>
        <div className="flex items-center mb-2 slider w-full">
          <button
            className="slider-arrows cursor-pointer text-secondary hover:text-accent"
            onClick={(e) => {
              if (!e.isDefaultPrevented()) slideToPrev(e);
            }}
            onKeyPress={(e) => {
              onEnterKeyPressDoCallback(e, () => {
                slideToPrev(e);
              });
            }}>
            <IconArrowLeft className="w-6 h-6 left-control arrow  " />
          </button>
          <div className="relative flex justify-center items-center w-full h-full ">
            {slides?.map((item, i) => {
              if (item.isImage) {
                const isCurrent = currentSlide === item.id;
                if (isCurrent)
                  return (
                    <LazyImage
                      id={`slide-${i}`}
                      src={item.url}
                      key={item.url}
                      className={` w-full bg-primary absolute inset-0 bottom-10 h-full select-none ${
                        !isCurrent ? 'hidden z-0 ' : '  flex z-20'
                      }`}
                      loadedClass="lazy-img-loaded"
                      placeholderClass="lazy-img-loading"
                      errorClass="lazy-img-error"
                      alt={`slide-${i}`}
                    />
                  );
              }
              if (!item.isImage) {
                const isCurrent = currentSlide === item.id;
                if (isCurrent)
                  return (
                    <video
                      key={item.url}
                      id={`video-${i}`}
                      className={` w-full bg-primary absolute inset-0 bottom-10 h-full select-none ${
                        !isCurrent ? 'hidden z-0 ' : ' flex z-20'
                      }`}>
                      <track
                        kind="captions"
                        autoPlay={true}
                        controls={true}
                        src={item.url}
                      />
                    </video>
                  );
              }
            })}
          </div>

          <button
            className="slider-arrows cursor-pointer text-secondary hover:text-accent "
            onClick={(e) => {
              if (!e.isDefaultPrevented()) slideToNext(e);
            }}
            onKeyPress={(e) => {
              onEnterKeyPressDoCallback(e, () => {
                slideToNext(e);
              });
            }}>
            <IconArrowLeft className="w-6 h-6 right-control arrow  " />
          </button>
        </div>
        <SliderDots info={{ slides, currentSlide, setSlideOnClick }} />
      </ModalSlider>
    );
  },
);

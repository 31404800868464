import { onEnterKeyPressDoCallback } from '@app/utils';
import { observer } from 'mobx-react';
import React from 'react';

import {
  IconCancel,
  IconDocumentList,
  IconSuccess,
  IconWarning,
} from '../../assets';
import { SuccessSnackbar } from './SuccessSnackbar';

export const InfoSnackbar = observer((props) => {
  const {
    message,
    className,
    onClose,
    style,
    callback,
    callbackActionText,
    isShow,
  } = props;

  return (
    <div
      onClick={onClose}
      onKeyDown={(e) => onEnterKeyPressDoCallback(e, onClose)}
      className={`flex flex-0 transition-all rounded-[56px] px-4 py-3 bg-secondary justify-between gap-10 ${
        className || ''
      } ${isShow ? 'lightSpeedInRight' : 'lightSpeedOutRight'}`}
      style={{
        animationDuration: '0.5s',
      }}>
      <button className="flex items-center gap-2" onClick={onClose}>
        <IconDocumentList className={'text-white min-w-4 min-h-4 w-4 h-4'} />
        <div className="snackbar-text select-none text-left">{message}</div>
      </button>
      {callback && (
        <>
          <button
            type={'button'}
            className="snackbar-button"
            onClick={() => {
              callback();
              onClose();
            }}>
            {callbackActionText}
          </button>
        </>
      )}
      <button
        type={'button'}
        className="snackbar-button flex justify-right"
        onClick={() => {
          onClose();
        }}>
        <IconCancel />
      </button>
    </div>
  );
});

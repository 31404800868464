import { Drawer, InterceptEventDetailed } from '@app/components';
import { inject, observer } from 'mobx-react';

export const InterceptEventDetailedDrawer = inject(({ store }) => ({
  isShow: store.ui.showDrawer.interceptEvent,
  onClose: () => {
    store.ui.setShowDrawer('interceptEvent', false);
    store.eventsInterceptions.clearCurrent();
  },
}))(
  observer((props) => {
    const { isShow, onClose } = props;
    return (
      <Drawer isShow={isShow} type="details" onCloseDrawer={onClose}>
        <InterceptEventDetailed />
      </Drawer>
    );
  }),
);

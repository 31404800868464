export const AccessIcon = ({ className }) => {
  return (
    <svg
      className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 9.16602H3.33333C2.8731 9.16602 2.5 9.53911 2.5 9.99935V17.4993C2.5 17.9596 2.8731 18.3327 3.33333 18.3327H16.6667C17.1269 18.3327 17.5 17.9596 17.5 17.4993V9.99935C17.5 9.53911 17.1269 9.16602 16.6667 9.16602Z"
        strokeWidth="2"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 9.16602V5.83268C5.83325 3.53149 7.69875 1.66602 9.99992 1.66602C12.3011 1.66602 14.1666 3.53149 14.1666 5.83268V9.16602"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5V15"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useRef, useState } from 'react';

export const InputCheckboxWithLabel = ({
  className,
  labelText,
  callback,
  disabled,
  checked,
}) => {
  const checkboxRef = useRef(null);
  if (!callback) {
    throw Error('Нужен колбэк для чекбокса :)');
  }

  const onChangeCheckbox = () => {
    callback(checkboxRef.current.checked);
  };

  return (
    <label
      className={`flex justify-start items-center cursor-pointer checkbox-wrap ${
        className ? className : ''
      }`}>
      {checked}
      <input
        ref={checkboxRef}
        type="checkbox"
        className="opacity-0 absolute checkbox-input"
        onChange={onChangeCheckbox}
        disabled={disabled}
        checked={checked && null}
        // defaultChecked={checked}
      />
      <span
        className={`w-5 h-5 border border-success mr-3 rounded-sm icon-checkmark transition duration-100 checkbox`}>
        <svg
          className="fill-current"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M5.66667 11.0572L3.13807 8.5286C2.87772 8.26825 2.45561 8.26825 2.19526 8.5286C1.93491 8.78895 1.93491 9.21105 2.19526 9.4714L5.19526 12.4714C5.45561 12.7318 5.87772 12.7318 6.13807 12.4714L13.4714 5.13807C13.7318 4.87772 13.7318 4.45561 13.4714 4.19526C13.2111 3.93491 12.7889 3.93491 12.5286 4.19526L5.66667 11.0572Z" />
        </svg>
      </span>
      <div className="select-none text-primary">{labelText}</div>
    </label>
  );
};

import React from 'react';
export const IconSearch = ({ className }) => (
  <svg
    className={`search-icon fill-current pointer-events-none ${className}`}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33337 2.08301C11.7852 2.08301 14.5834 4.88123 14.5834 8.33301C14.5834 9.75813 14.1064 11.0719 13.3034 12.1232L17.256 16.0771C17.5814 16.4025 17.5814 16.9302 17.256 17.2556C16.9556 17.556 16.4829 17.5791 16.156 17.3249L16.0775 17.2556L12.1236 13.303C11.0722 14.106 9.7585 14.583 8.33337 14.583C4.88159 14.583 2.08337 11.7848 2.08337 8.33301C2.08337 4.88123 4.88159 2.08301 8.33337 2.08301ZM8.33337 3.74967C5.80207 3.74967 3.75004 5.8017 3.75004 8.33301C3.75004 10.8643 5.80207 12.9163 8.33337 12.9163C10.8647 12.9163 12.9167 10.8643 12.9167 8.33301C12.9167 5.8017 10.8647 3.74967 8.33337 3.74967Z" />
  </svg>
);

import { PortalModal } from '@app/components';
import { ReactComponent as Close } from '@component/Map/icons/close-small.svg';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from './components';
import styles from './Drawer.module.css';

export const Drawer = ({ type, children, onCloseDrawer, isShow }) => {
  let modalRef = useRef(null);
  let modalWrapperRef = useRef(null);
  let closeIconRef = useRef(null);
  const { t } = useTranslation('title');

  function closeOnEscKeyPressed(e) {
    if (e.keyCode === 27 && isShow && !e.ctrlKey && !e.shiftKey && !e.altKey) {
      onCloseDrawer();
    }
  }

  const closeModal = (event) => {
    console.log(modalRef, 'modalWrapperRef');
    const domNodeBar = modalRef.current;
    const domCloseIconNode = closeIconRef.current;
    if (event.target == domNodeBar) {
      return onCloseDrawer();
    }
    if (
      event.target === domCloseIconNode ||
      domCloseIconNode.contains(event.target)
    ) {
      onCloseDrawer();
    }
    if (domNodeBar.contains(event.target)) {
      return null;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscKeyPressed);
    return () => {
      document.removeEventListener('keydown', closeOnEscKeyPressed);
    };
  }, [isShow]);

  return (
    <PortalModal
      ref={modalWrapperRef}
      isShow={isShow}
      Element={({ state }) => (
        <div ref={modalWrapperRef}>
          <div
            ref={modalRef}
            tabIndex={0}
            role={'button'}
            onClick={closeModal}
            className={`${styles.container} drawer-animation-${state}`}>
            <div
              className={`${styles.drawer} drawer bg-white dark:bg-dark ${
                type === 'cards' && '!w-[280px]'
              }`}>
              <header
                className={`${
                  (type === 'details' || type === 'details_wanted') &&
                  styles.drawerHeader
                } relative p-4 h-18`}>
                {type === 'details' ? <h3>{t('eventDetails')}</h3> : ''}
                {type === 'details_wanted' ? <h3>{t('wantedDetails')}</h3> : ''}
                {type === 'cards' && <Search />}
                <button
                  ref={closeIconRef}
                  className={`${styles.button} bg-white dark:bg-dark stroke-current text-primary dark:text-white`}
                  onClick={closeModal}>
                  <Close />
                </button>
              </header>
              <div
                className="flex flex-col "
                style={{ height: 'calc(100vh - 56px' }}>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

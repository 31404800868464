import React from 'react';

import { CallbackButton } from '../HOC';

export const MenuHamburger = ({
  onClick,
  isOpenMenu,
  menuList,
  menuRef,
  className,
  ...props
}) => {
  return (
    <CallbackButton
      {...props}
      className={`hamburger-container  ${className || ''}`}
      _ref={menuRef}
      callback={onClick}>
      <div className={`menu-hamburger ${isOpenMenu ? 'open-menu' : ''}`}>
        <span />
        <span />
        <span />
        <span />
      </div>
    </CallbackButton>
  );
};

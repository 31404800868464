import { t } from 'i18next';
import React, { useEffect } from 'react';

export const InputSelect = ({
  callback,
  textError,
  hasErrors,
  options,
  disabled,
  defaultValue,
  className = '',
  labelText,
  autocomplete,
  inputRef,
  emptyRow = true,
  type = 'line',
}) => {
  const ref = inputRef;

  if (options === undefined) {
    throw Error('Нужен options для селекта :)');
  }
  const onChange = (e) => {
    if (!callback) {
      throw Error('Нужен колбэк для селекта :)');
    }
    callback(e.target.value);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <div
        className={`relative inline-block input-select__wrap ${
          hasErrors ? 'error' : ''
        }  `}>
        <select
          ref={ref}
          autoComplete={autocomplete ? 'on' : 'off'}
          value={defaultValue ?? options[0]?.value}
          onChange={onChange}
          data-value=""
          disabled={disabled}
          className={`
            input-select
            ${type === 'rounded' ? `input-select-rounded` : ``}
            `}>
          {emptyRow && (
            <option value={''}>{t('choose', { ns: 'inputs' })}</option>
          )}
          {options.map((option) => {
            return (
              <option
                key={option.label}
                value={option.value}
                disabled={option.disabled}>
                {option.label}
              </option>
            );
          })}
        </select>
        <label className="absolute pointer-events-none text-gray duration-200 ease">
          {labelText}
        </label>
        <div
          className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 arrow hover:text-accent text-gray dark:text-secondary ${
            disabled ? 'disabled' : ''
          }`}>
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      {hasErrors && <div className="text-accent text-sm">{textError}</div>}
    </div>
  );
};

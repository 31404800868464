export const MapIcon = ({ className, active = false }) => {
  return (
    <svg
    className={`stroke-current ${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.08341 5L1.66675 2.5V15L7.08341 17.5L12.9167 15L18.3334 17.5V5L12.9167 2.5L7.08341 5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12.9167 2.5V15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.08325 5V17.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.375 3.75L7.08333 5L12.9167 2.5L15.625 3.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.375 16.25L7.08333 17.5L12.9167 15L15.625 16.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

import { PageContainer } from '@app/layouts/PageContainer';

export const NotFoundPage = () => (
  <PageContainer className={'flex w-full h-full'}>
    <div className="flex items-center justify-center w-full h-screen min-h-screen">
      <h1 className="text-3xl text-bold text-accent">
        404 | Такой страницы не существует
      </h1>
    </div>
  </PageContainer>
);

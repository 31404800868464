import { RedirectIfIsAuth } from '@app/router/RedirectIfIsAuth';
import { AppVersion } from '@component/AppVersion';
import { SelectLanguage } from '@component/SelectLanguage';
import { defaultApiUrl } from '@utils/constants';
import { debounce } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonFilled,
  HeaderMain,
  Input,
  InputPassword,
  PreloaderIcon,
} from '../components';

const AuthorizationPage = inject(({ store }) => ({
  sendAuthData: store.auth.accountLogin,
  isLoading: store.auth.isLoading,
  isAuth: store.auth.isAuth,
  checkConnect: store.auth.checkCanConnect,
}))(
  observer((props) => {
    const { sendAuthData, checkConnect, isLoading, isAuth } = props;

    const [canConnect, setCanConnect] = useState(false);

    const checkConnectAvailable = debounce(async (api) => {
      setCanConnect(await checkConnect(api));
    }, 500);

    const auth = useLocalObservable(() => ({
      api: '',
      name: '',
      password: '',
      apiError: false,
      errorText: '',
      get wrongProtocol() {
        const apiProtocol = this.api.substring(0, this.api.indexOf('/'));
        const winProtocol = window.location.protocol;
        if (winProtocol === 'https:') {
          return !import.meta.env.DEV && winProtocol !== apiProtocol;
        }
        return false;
      },
      setApiValue(api) {
        this.api = api;
        checkConnectAvailable(api);
      },
      setLoginValue(name) {
        this.name = name;
      },
      setPasswordValue(password) {
        this.password = password;
      },
    }));

    const { t } = useTranslation('title', 'buttons');

    const onSubmit = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isLoading) {
        try {
          await sendAuthData({
            name: auth.name,
            password: auth.password,
            api: auth.api,
          });
        } catch (e) {
          console.log(e, 'onSubmit auth err');
        }
      }
    };

    useEffect(() => {
      auth.setApiValue(defaultApiUrl());
    }, []);

    if (isAuth) return <RedirectIfIsAuth />;
    return (
      <>
        <div className={'flex flex-col w-full items-center  pt-24'}>
          <HeaderMain />
          <div className="flex flex-col w-full  items-center justify-start px-8">
            <form
              onSubmit={onSubmit}
              className="flex flex-col w-full  items-center justify-center max-w-[470px]">
              <div className="flex items-center">
                <h3 className="text-2xl text-primary dark:text-white mb-8">
                  {t('loginIn')}
                </h3>
                {isLoading && (
                  <PreloaderIcon
                    className={
                      'text-accent flex items-center justify-center  w-12 h-12 min-w-12 mb-8'
                    }
                  />
                )}
              </div>
              <Input
                labelText={t('API', 'API')}
                defaultValue={auth.api}
                callback={(value) => auth.setApiValue(value)}
              />
              <Input
                className={'mt-4'}
                labelText={t('login')}
                defaultValue={auth.name}
                callback={(value) => auth.setLoginValue(value)}
              />
              <InputPassword
                className={'mt-4'}
                defaultValue={auth.password}
                callback={(value) => auth.setPasswordValue(value)}
              />

              <ButtonFilled
                disabled={isLoading || !canConnect}
                submit
                text={t('enter')}
                className={'mt-8 w-full'}
              />
              <div className="mt-5 w-full">
                <SelectLanguage />
              </div>
              <AppVersion className={'mt-5'} />
            </form>
            {auth.wrongProtocol && (
              <div className="flex p-2 text-white bg-accent rounded mt-4 justify-center max-w-[470px]">
                <span>
                  Страница открыта через https, нельзя подключиться к API EGSV2
                  по http.
                  <a
                    href={`http://${window.location.host}`}
                    className="underline">
                    Перейдите на http.
                  </a>
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }),
);
export { AuthorizationPage };

import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

import { IconCalendar, IconCross } from '../../../assets';
import { formatTimestampByPattern } from '@app/utils';

export const InputDateTime = observer(({ className, callback, value }) => {
  const [date, setDate] = useState(null);
  useEffect(() => {
    if (value) {
      setDate(new Date(value));
    } else {
      setDate(undefined);
    }
  }, [value]);

  return (
    <div className={`relative flex flex-row  ${className ? className : ''}`}>
      <DateTimePicker
        locale={'ru-RU'}
        className={
          'dark:bg-bg-3 dark:text-white group bg-bg outline-none rounded-[56px] -hidden'
        }
        calendarIcon={
          <IconCalendar
            className={'min-w-6 w-6 text-secondary group-focus:text-accent'}
          />
        }
        clearIcon={
          <IconCross
            className={'min-w-6 w-6 text-secondary group-focus:text-accent'}
          />
        }
        disableClock={true}
        format={'dd-MM-yyy HH:mm:ss'}
        onChange={(v) => {
          setDate(v);
          callback(
            formatTimestampByPattern({
              timestamp: v?.getTime(),
              pattern: 'YYYY-MM-DDTHH:mm:ss',
            }) ?? undefined,
          );
        }}
        value={date}
      />
    </div>
  );
});

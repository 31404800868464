import React from 'react';

import { CallbackButton } from '../../HOC';

export const ButtonTransparent = ({
  className,
  disabled,
  submit,
  callback,
  text,
  ...props
}) => {
  const _callbackButton = () => {
    if (!submit && callback) {
      callback();
    }
  };
  return (
    <CallbackButton
      {...props}
      disabled={disabled}
      callback={_callbackButton}
      type={submit ? 'submit' : 'button'}
      className={`button uppercase button-transparent dark:hover:bg-bg-2   focus:outline-black dark:focus:outline-white ${
        className || ''
      }`}>
      {text}
    </CallbackButton>
  );
};

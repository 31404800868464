import React from 'react';

import { PreloaderIcon } from '../../_other';

export const RouteLoading = () => (
  <div className="w-full h-full flex items-center justify-center mt-40">
    <PreloaderIcon className="w-24 h-24 text-accent  flex items-center justify-center" />
  </div>
);
export const PageLoading = (props) => {
  const { isLoading, children, className = '' } = props;
  return (
    <div className={className}>
      {isLoading ? (
        <div className="flex mt-10 h-24 justify-center w-full ">
          <PreloaderIcon className=" w-24 h-24 text-accent" />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

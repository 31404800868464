import { uiService } from '@app/stores/mainStore.store';
import { KorgauLogo } from '@asset/icons/svgJsIcons/KorgauLogo';
import { inject, observer } from 'mobx-react';

export const HeaderMain = inject(({ store }) => ({}))(
  observer(() => {
    const isDarkTheme = uiService.isDarkTheme;
    return (
      <div className="p-8  flex justify-center w-full h-auto ">
        <div className="flex flex-col items-center justify-center  sm:p-3">
          <div className="flex h-32">
            <KorgauLogo className="dark:text-white  text-primary" />
          </div>
        </div>
      </div>
    );
  }),
);

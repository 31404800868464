import { inject, observer } from 'mobx-react';

export const AppVersion = inject(({ store }) => ({
  apiVersion: store.auth.apiVersion,
}))(
  observer(({ apiVersion, className = '' }) => {
    const versions = Object.entries(apiVersion).map(
      ([key, value]) =>
        value && <span key={key + value}>{`${key}: ${value}`}</span>,
    );
    return (
      <div className={` flex flex-col text-xs items-center ${className}`}>
        {versions}
      </div>
    );
  }),
);

import { inject, observer } from 'mobx-react';
import { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { RouteLoading } from '../components';
import { PageContainer } from '../layouts/PageContainer';
import { NotFoundPage } from '../pages/NotFound';

const ListPage = lazy(() => import('../pages/Users/routes/ListPage'));
const CreatePage = lazy(() => import('../pages/Users/routes/CreatePage'));
const EditPage = lazy(() => import('../pages/Users/routes/EditPage'));


export const RoutesUsers = inject(({ store }) => ({}))(
  observer(() => {
    let { path } = useRouteMatch();
    const homePath = path;
    return (
      <>
        <Suspense fallback={<RouteLoading />}>
          <PageContainer>
            <Switch>
              <Route path={homePath}>
                <Switch>
                  <Route exact path={homePath + '/create'}>
                    <CreatePage goBackUrl={homePath} />
                  </Route>
                  <Route exact path={homePath + '/:userId/edit'}>
                    <EditPage goBackUrl={homePath} />
                  </Route>
                  <Route path={'/'}>
                    <ListPage goBackUrl={homePath} />
                  </Route>
                  <Route path={'*'}>
                    <NotFoundPage goBackUrl={homePath} />
                  </Route>
                </Switch>
              </Route>

              <Redirect from={`${path}`} to={homePath} />
              <Route path={'*'}>
                <NotFoundPage goBackUrl={homePath} />
              </Route>
            </Switch>
          </PageContainer>
        </Suspense>
      </>
    );
  }),
);
